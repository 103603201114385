import React from "react";
import {ProFormCheckbox} from "@ant-design/pro-form";
import {TELESCOPE_FILTER, TELESCOPE_FILTER_LIST} from "../../strings/field_names";
import {request} from "../../api/api";
import {OBSERVATION_TELESCOPE_DETAIL_URL} from "../../strings/request_urls";
import {message} from "antd";

export default function TelescopeFilter({telescope, disabled}) {
    if (!telescope) {
        return null
    }
    return (
        <ProFormCheckbox.Group
            name={TELESCOPE_FILTER}
            label={"درخواست رصد در فیلتر (های)"}
            disabled={disabled}
            request={async () => {
                try {
                    const telescopeDetail = await request(
                        OBSERVATION_TELESCOPE_DETAIL_URL + telescope + "/")
                    return telescopeDetail[TELESCOPE_FILTER_LIST]
                } catch (e) {
                    message.error("خطا در دریافت لیست ابزار رصد").then()
                    return []
                }
            }}
        />
    )
}