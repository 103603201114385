import {TELESCOPE} from "../../../strings/field_names";
import {callFunction, request} from "../../../api/api";
import {Alert} from "antd";
import ProForm, {ProFormRadio} from "@ant-design/pro-form";
import React, {useRef} from "react";
import {OBSERVATION_TELESCOPES_LIST_URL} from "../../../strings/request_urls";

export default function TelescopeForm({observationSubject, onFinish, onCancel}) {
    const formRef = useRef();

    if (!observationSubject) {
        return null
    }

    return (
        <ProForm
            formRef={formRef}
            name={TELESCOPE}
            onFinish={(values) => {
                callFunction(onFinish, values[TELESCOPE])
            }}
            submitter={{
                searchConfig: {
                    resetText: 'بازگشت',
                    submitText: 'تایید',
                },
                resetButtonProps: {
                    onClick: () => {
                        callFunction(onCancel)
                    },
                },
            }}
        >
            <Alert message={"ابزار رصد را انتخاب نمایید."} type={"info"}/>
            <br/>
            <ProFormRadio.Group
                name={TELESCOPE}
                layout="vertical"
                request={async () => {
                    return await request(OBSERVATION_TELESCOPES_LIST_URL + observationSubject + "/")
                }}
                rules={[{required: true, message: 'لطفا ابزار رصد را انتخاب نمایید'}]}
            />
            <Alert message={"جهت آشنایی بیشتر با ابزارهای رصد به بخش آموزش مراجعه نمایید."}
                   type={"warning"}/>
            <br/>
        </ProForm>
    )
}