import React, {useRef} from 'react';
import '../account_page.css';
import {CODE, DATA, KEY, METHOD, RANDOM_KEY} from "../../../strings/field_names";
import ResendCode from "../../../components/inputs/resend_code";
import {callFunction, request} from "../../../api/api";
import {ModalForm, ProFormText} from '@ant-design/pro-form';
import * as Icons from "@ant-design/icons";
import {USER_RANDOM_NUMBER_URL} from "../../../strings/request_urls";

export default function RandomNumberForm({randomKey, setRandomKey, onFinish}) {
    return (
        <ModalForm
            name="random_number"
            size={"large"}
            autoFocusFirstInput={true}
            isKeyPressSubmit={true}
            visible={randomKey}
            onVisibleChange={(visible) => {
                if (!visible) {
                    setRandomKey(null)
                }
            }}
            width={400}
            onFinish={async (values) => {
                const response = await request(USER_RANDOM_NUMBER_URL, {
                    [METHOD]: "POST",
                    [DATA]: {
                        [RANDOM_KEY]: randomKey,
                        [CODE]: values[CODE]
                    },
                })
                callFunction(onFinish, response)
                setRandomKey(null)
            }}
        >
            <ProFormText
                name={CODE}
                fieldProps={{
                    prefix: <Icons.QrcodeOutlined/>,
                    maxLength: 6
                }}
                placeholder={"کد فعال سازی"}
                rules={[{required: true}, {len: 6}]}
            />
            <ResendCode onResend={async () => {
                await request(USER_RANDOM_NUMBER_URL, {
                    [METHOD]: "POST",
                    [DATA]: {
                        [KEY]: randomKey
                    }
                })
            }} seconds={120}/>
        </ModalForm>

    )
}