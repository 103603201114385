import React, {useContext} from "react";
import {Card, List} from "antd";
import {PageContainer} from "@ant-design/pro-layout";
import {KEY, TEXT, USER_URL} from "../../strings/field_names";
import {UserContext} from "../../components/contexts/user_context";

export default function DashboardContainer() {
    const [user,] = useContext(UserContext)

    const list_data = [
        {
            [KEY]: 0,
            [URL]: user[USER_URL],
            [TEXT]: "رصد آنلاین"
        },
        {
            [KEY]: 1,
            [URL]: "/observation_request",
            [TEXT]: "درخواست رصد"
        },
        {
            [KEY]: 2,
            [URL]: "/observation_request_list",
            [TEXT]: "لیست درخواست‌ها"
        },
        {
            [KEY]: 3,
            [TEXT]: "دریافت داده"
        },
        {
            [KEY]: 4,
            [TEXT]: "ارسال نتایج"
        },
        {
            [KEY]: 5,
            [TEXT]: "نظرسنجی"
        },
        {
            [KEY]: 6,
            [TEXT]: "معرفی رصدخانه"
        },
        {
            [KEY]: 7,
            [TEXT]: "آموزش‌ها"
        }
    ]

    return (
        <PageContainer title={"پیشخوان کاربر"}>
            <div style={{padding: 20}}>
                <List
                    grid={{
                        gutter: 50,
                        xs: 1,
                        sm: 1,
                        md: 2,
                        lg: 3,
                        xl: 4,
                        xxl: 4,
                    }}
                    rowKey={KEY}
                    dataSource={list_data}
                    renderItem={item => <List.Item
                        key={item[KEY]}
                        actions={[]}>
                        <a href={item[URL]}>
                            <Card
                                hoverable style={{
                                borderRadius: 20,
                                backgroundColor: "#0a4b78",
                            }}
                            >
                                <div style={{fontSize: 30, fontWeight: "bold", textAlign: "center", color: "white"}}>
                                    {item[TEXT]}
                                </div>
                            </Card>
                        </a>
                    </List.Item>}
                />
            </div>
        </PageContainer>
    )
}