import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "antd/dist/antd.css";
import {ConfigProvider} from "antd";
// import locale from 'antd/es/date-picker/locale/fa_IR';
import faIR from 'antd/lib/locale/fa_IR';
import UserProvider from "./components/contexts/user_context";

ReactDOM.render(
    <UserProvider>
        <ConfigProvider locale={faIR} componentSize={"large"} direction={"rtl"} size={"large"}
                        space={{size: "large"}}>
            <App/>
        </ConfigProvider>
    </UserProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
