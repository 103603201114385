import React from "react";
import {ProFormText} from "@ant-design/pro-form";
import {APPARENT_VALUE, CONSTELLATION, PERIOD, RELATED_MATERIALS_NAME} from "../../../strings/field_names";
import Coordinate from "../../../components/inputs/coordinate";
import TelescopeFilter from "../../../components/inputs/telescope_filter";

export default function PhotometryRequestForm({telescope, disabled}) {

    return (
        <div>
            <ProFormText name={APPARENT_VALUE} label={"قدر ظاهری"}
                         disabled={disabled}
                         placeholder={null} rules={[{required: true}]}/>
            <ProFormText name={RELATED_MATERIALS_NAME} label={"نام اجرام وابسته"}
                         placeholder={null} rules={[{required: true}]}
                         disabled={disabled}
                         tooltip={"مشابه نام جرم باشد"}/>
            <ProFormText name={PERIOD} label={"دوره تناوب"}
                         placeholder={null} rules={[{required: true}]}
                         disabled={disabled}
                         tooltip={"به ساعت"}/>
            <ProFormText name={CONSTELLATION} label={"صورت فلکی"}
                         disabled={disabled}
                         placeholder={null} rules={[{required: true}]}/>
            <TelescopeFilter telescope={telescope} disabled={disabled}/>
            <Coordinate disabled={disabled}/>
        </div>
    )
}