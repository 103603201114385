import React, {useCallback, useEffect, useState} from "react";
import {PageContainer} from "@ant-design/pro-layout";
import {Button, Descriptions, Popconfirm, Spin} from "antd";
import {
    END_DT, ID,
    IGNORE_REASON,
    INSERT_DT,
    MATERIAL_NAME,
    OBSERVATION_PURPOSE,
    OBSERVATION_REQUEST_TIME,
    OBSERVATION_STATE,
    OBSERVATION_SUBJECT,
    REASON,
    START_DT,
    TELESCOPE,
    USER_URL
} from "../../strings/field_names";
import {request} from "../../api/api";
import {TimeRender} from "../../statics/utils";
import {useParams} from 'react-router-dom'
import ObservationRequestTime from "./observation_request_times";
import {OBSERVATION_REQUEST_LIST_URL} from "../../strings/request_urls";
import {
    renderObservationPurpose,
    renderObservationState,
    renderObservationSubject,
    renderSkyroom,
    renderTelescope
} from "./observation_request_list";
import {observationAction} from "../../api/observation_api";
import ObservationRequestForm from "./steps/observation_request_form";
import ProCard from "@ant-design/pro-card";
import {completeRequest} from "./observation_request";

export default function ObservationRequestDetail() {
    const [observationRequest, setObservationRequest] = useState({})
    const [loading, setLoading] = useState(false)
    const {id} = useParams()
    const url = OBSERVATION_REQUEST_LIST_URL + id + "/"
    const [tab, setTab] = useState('detail')

    useEffect(() => {
        updateObservationRequest()
    }, [])

    const updateObservationRequest = useCallback(() => {
        request(url, null, setLoading).then((response) => {
            setObservationRequest(response)
        })
    }, [])

    const Action = ({state, type, text}) => {
        return (
            <Popconfirm title="آیا مطمئنید؟" okText="بله" cancelText="خیر" onConfirm={() => {
                observationAction(id, state, setLoading).then((response) => {
                    setObservationRequest({...observationRequest, ...response})
                })
            }}>
                <Button type={type} loading={loading}>
                    {text}
                </Button>
            </Popconfirm>
        )
    }

    const Detail = () => {
        return (
            <Spin spinning={loading}>
                <Descriptions bordered>
                    <Descriptions.Item label="نوع رصد">
                        {renderObservationSubject(observationRequest[OBSERVATION_SUBJECT])}
                    </Descriptions.Item>
                    <Descriptions.Item label="ابزار رصد">
                        {renderTelescope(observationRequest[TELESCOPE])}
                    </Descriptions.Item>
                    <Descriptions.Item label="وضعیت">
                        {renderObservationState(observationRequest[OBSERVATION_STATE])}
                    </Descriptions.Item>
                    <Descriptions.Item label="نام جرم">{observationRequest[MATERIAL_NAME]}</Descriptions.Item>
                    {observationRequest[OBSERVATION_REQUEST_TIME] ?
                        <Descriptions.Item label="تاریخ شروع رصد">
                            {<TimeRender time={observationRequest[OBSERVATION_REQUEST_TIME][START_DT]}/>}
                        </Descriptions.Item> : null}
                    {observationRequest[OBSERVATION_REQUEST_TIME] ?
                        <Descriptions.Item label="تاریخ پایان رصد">
                            {<TimeRender time={observationRequest[OBSERVATION_REQUEST_TIME][END_DT]}/>}
                        </Descriptions.Item> : null}
                    {observationRequest[IGNORE_REASON] ?
                        <Descriptions.Item label="علت رد درخواست">
                            {observationRequest[IGNORE_REASON]}
                        </Descriptions.Item> : null}
                    <Descriptions.Item label="هدف از رصد">
                        {renderObservationPurpose(observationRequest[OBSERVATION_PURPOSE])}
                    </Descriptions.Item>
                    <Descriptions.Item label="علت رصد">{observationRequest[REASON]}</Descriptions.Item>
                    <Descriptions.Item label="تاریخ درخواست">
                        <TimeRender time={observationRequest[INSERT_DT]}/>
                    </Descriptions.Item>
                </Descriptions>
            </Spin>
        )

    }

    return (
        <PageContainer
            content={<Detail/>}
            extra={observationRequest[OBSERVATION_STATE]?.id === 1 ? [
                <Button type={"primary"} onClick={() => {
                    completeRequest(id, (response) => {
                        setObservationRequest({...observationRequest, ...response})
                    })
                }}>
                    {"تکمیل درخواست"}
                </Button>,
                // <Action state={2} type={"primary"} text={"تکمیل درخواست"}/>,
                <Action state={3} type={"ghost"} text={"انصراف از درخواست"}/>,
            ] : [
                renderSkyroom(observationRequest[USER_URL])
            ]}
            tabList={[
                {
                    tab: 'اطلاعات',
                    key: 'detail',
                },
                {
                    tab: 'زمانبندی رصد',
                    key: 'time',
                },
            ]}
            tabActiveKey={tab}
            onTabChange={(newTab) => setTab(newTab)}
        >
            {observationRequest && tab === "detail" ?
                <div className={"mirror"}>
                    <ObservationRequestForm
                        observationRequest={observationRequest[ID]}
                        observationSubject={observationRequest[OBSERVATION_SUBJECT]?.id}
                        telescope={observationRequest[TELESCOPE]?.id}
                        onFinish={observationRequest[OBSERVATION_STATE]?.id === 1 ? () => {
                            updateObservationRequest()
                        } : null}/>
                </div>
                : null}
            {observationRequest && tab === "time" ?
                <ObservationRequestTime
                    observationRequest={observationRequest[ID]}
                    editable={observationRequest[OBSERVATION_STATE]?.id === 1}/> : null}

        </PageContainer>
    )
}