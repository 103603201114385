import {AVATAR, DATA, METHOD} from "../../strings/field_names";
import {Image, message, Spin, Tooltip, Typography, Upload} from "antd";
import {beforeUploadImage} from "../../statics/utils";
import {EditIcon} from "../../statics/icons";
import React, {useState} from "react";
import {build_url, callFunction, get_authorization, request} from "../../api/api";
import ImgCrop from 'antd-img-crop';

export default function EditableAvatar({name = AVATAR, action, method = "PATCH", detail, onFinish}) {
    const [loading, setLoading] = useState(false)
    return (
        <span>
            <Spin spinning={loading}>
                <Tooltip title={"مشاهده"}>
                    <Image src={detail[name]} width={32}/>
                </Tooltip>
                {" "}
                <ImgCrop modalTitle={"ویرایش"} modalOk={"تایید"} modalCancel={"انصراف"}>
                    <Upload
                        name={name}
                        listType="picture"
                        action={build_url(action)}
                        method={method}
                        headers={get_authorization()}
                        showUploadList={false}
                        withCredentials={false}
                        onChange={(info) => {
                            if (info.file.status === 'uploading') {
                                setLoading(true)
                            }
                            if (info.file.status === 'done') {
                                setLoading(false)
                                message.success("تغییر تصویر با موفقیت انجام شد.").then();
                                callFunction(onFinish, info)
                            }
                            if (info.file.status === 'error') {
                                setLoading(false)
                                message.error("تغییر تصویر با خطا مواجه شد.").then();
                            }
                        }}
                        beforeUpload={beforeUploadImage}
                    >
                        <Typography.Text editable={{
                            icon: <EditIcon/>,
                            tooltip: "ویرایش",
                            editing: false,
                        }}/>
                    </Upload>
                </ImgCrop>
            </Spin>
        </span>
    )
}