import React, {useRef, useState} from 'react';
import '../account_page.css';
import {callFunction, request} from "../../../api/api";
import {
    STATIC_EDUCATION_FIELD_URL,
    STATIC_EDUCATION_GRADE_URL,
    STATIC_PROVINCE_URL,
    USER_DETAIL_URL
} from "../../../strings/request_urls";
import ProForm, {ProFormSelect, ProFormText, ProFormTextArea} from '@ant-design/pro-form';
import {
    CITY,
    COMPANY,
    DATA,
    EDUCATION_FIELD,
    EDUCATION_GRADE,
    FORM,
    METHOD,
    PROVINCE,
    VALUE
} from "../../../strings/field_names";
import {message} from "antd";

export default function UserDetailForm({onFinish}) {
    const formRef = useRef();
    const [provinces, setProvinces] = useState([])
    const [province, setProvince] = useState(null)

    return (
        <ProForm
            formRef={formRef}
            name="user_detail"
            onValuesChange={(newData, _) => {
                if (newData[PROVINCE]) {
                    formRef.current?.setFieldsValue({[CITY]: null})
                    setProvince(provinces.find((province) => province[VALUE] === newData[PROVINCE]))
                }
            }}
            onFinish={async (values) => {
                const sendData = {
                    ...values,
                    [EDUCATION_FIELD]: values[EDUCATION_FIELD]?.value || values[EDUCATION_FIELD],
                    [EDUCATION_GRADE]: values[EDUCATION_GRADE]?.value || values[EDUCATION_GRADE],
                    [CITY]: values[CITY]?.value || values[CITY],
                }
                const response = await request(USER_DETAIL_URL, {
                    [METHOD]: "PUT",
                    [DATA]: sendData,
                    [FORM]: formRef.current,
                })
                if (response) {
                    callFunction(onFinish, response)
                    message.success("اطلاعات با موفقیت ثبت شد")
                }
            }}
            request={async () => {
                const response = await request(USER_DETAIL_URL)
                setProvince(response[PROVINCE])
                return response
            }}
            submitter={{
                searchConfig: {
                    submitText: 'ثبت اطلاعات',
                },
                resetButtonProps: {
                    style: {
                        display: "none"
                    }
                },
            }}
        >
            <ProFormSelect
                showSearch
                name={EDUCATION_GRADE}
                placeholder={"میزان تحصیلات"} label={"میزان تحصیلات"}
                request={async ({keyWords=""}) => {
                    return await request(STATIC_EDUCATION_GRADE_URL + "?search=" + keyWords)
                }}
                rules={[{required: true}]}
                cacheForSwr={true}
            />
            <ProFormSelect
                showSearch
                name={EDUCATION_FIELD}
                placeholder={"رشته تحصیلی"} label={"رشته تحصیلی"}
                request={async ({keyWords=""}) => {
                    const response = await request(STATIC_EDUCATION_FIELD_URL + "?search=" + keyWords)
                    return response.results
                }}
                cacheForSwr={true}
                rules={[{required: true}]}
            />
            <ProFormText name={"job"} placeholder={"شغل"} label={"شغل"} rules={[{required: true}]}/>
            <ProFormText name={COMPANY} placeholder={"نام موسسه محل اشتغال یا تحصیل"}
                         label={"نام موسسه محل اشتغال یا تحصیل"} rules={[{required: true}]}/>
            <ProFormSelect
                showSearch
                name={PROVINCE}
                placeholder={"استان"} label={"استان"}
                request={async ({keyWords=""}) => {
                    const response = await request(STATIC_PROVINCE_URL + "?search=" + keyWords)
                    setProvinces(response)
                    return response
                }}
                cacheForSwr={true}
                rules={[{required: true}]}
            />
            <ProFormSelect
                showSearch
                disabled={!province}
                name={CITY}
                placeholder={"شهر"} label={"شهر"}
                options={province?.cities}
                rules={[{required: true}]}
            />
            <ProFormTextArea name={"address"} placeholder={"آدرس"} label={"آدرس"} rules={[{required: true}]}/>
            <ProFormText name={"astronomical_center"} placeholder={"موسسه یا مرکز نجومی"}
                         label={"درصورت عضویت یا فعالیت در گروه، موسسه یا مرکز نجومی، لطفا نام آن را ذکر کنید"}/>
        </ProForm>
    )
}