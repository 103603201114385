import {message, Spin, Typography} from "antd";
import React, {useState} from "react";
import {build_url, request} from "../../api/api";
import {DATA, ERROR, FAIL, HANDLE, METHOD, SUCCESS} from "../../strings/field_names";

export default function EditableText({name, action, method = "PATCH", detail, onFinish}) {
    const [loading, setLoading] = useState(false)

    return (
        <Spin spinning={loading}>
            <Typography.Text editable={{
                tooltip: "ویرایش",
                onChange: (newData) => {
                    if (newData !== detail[name]) {
                        request(action, {
                            [METHOD]: method,
                            [DATA]: {
                                [name]: newData
                            },
                            [HANDLE]: {
                                [SUCCESS]: onFinish,
                                [FAIL]: () => {
                                    message.error("درخواست با خطا مواجه شد").then()
                                },
                                [ERROR]: () => {
                                    message.error("درخواست با خطا مواجه شد").then()
                                }
                            }
                        }, setLoading).then()
                    }
                },
            }}>{detail[name]}</Typography.Text>
        </Spin>
    )
}