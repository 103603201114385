import './loading_page.css';
import {Image, Spin} from 'antd';
import React from "react";

export default function LoadingPage() {
    return (
        <div className={"header_logo"}>
            <div>
                <Image
                    width={256}
                    src={"/header_logo.png"}
                />
                <div className="page-loading-warp">
                    <div className="ant-spin ant-spin-lg ant-spin-spinning">
                        <Spin size="large"/>
                    </div>
                </div>
            </div>
        </div>
    )
}