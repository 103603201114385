import React from "react";
import {PageContainer} from "@ant-design/pro-layout";
import ProTable from '@ant-design/pro-table';
import {request} from "../../api/api";
import {
    DETAIL,
    END_DT,
    FULL_DETAIL,
    ID,
    INSERT_DT,
    MATERIAL_NAME,
    NAME,
    OBSERVATION_PURPOSE,
    OBSERVATION_REQUEST_TIME,
    OBSERVATION_STATE,
    OBSERVATION_SUBJECT,
    REASON,
    START_DT,
    STATUS,
    TELESCOPE,
    USER_URL
} from "../../strings/field_names";
import {TimeRender} from "../../statics/utils";
import {OBSERVATION_REQUEST_LIST_URL} from "../../strings/request_urls";
import {Badge, Button, Tooltip} from "antd";

export const renderObservationSubject = (observationSubject) => {
    if (!observationSubject) {
        return null
    }
    return (
        <Tooltip title={observationSubject[DETAIL]}>
            {observationSubject[NAME]}
        </Tooltip>)
}

export const renderTelescope = (telescope) => {
    if (!telescope) {
        return null
    }
    return (
        <div title={telescope[FULL_DETAIL]}>
            {telescope[NAME]}
        </div>)
}
export const renderObservationState = (observationState) => {
    if (!observationState) {
        return null
    }
    return (
        <Tooltip title={observationState[DETAIL]}>
            <Badge
                status={observationState[STATUS]}
                text={observationState[NAME]}/>
        </Tooltip>)
}
export const renderObservationPurpose = (observationPurpose) => {
    if (!observationPurpose) {
        return null
    }
    return (
        <Tooltip title={observationPurpose[DETAIL]}>
            {observationPurpose[NAME]}
        </Tooltip>)
}
export const renderSkyroom = (userUrl) => {
    if (!userUrl || userUrl === "-") {
        return null
    }
    return (
        <a href={userUrl} target={"_blank"}>
            <Button type="primary">
                ورود به رصد آنلاین
            </Button>
        </a>)
}

export default function ObservationRequestList({}) {
    const columns = [
        {
            dataIndex: ID,
            title: 'مشاهده فرم',
            width: 50,
            render: (id) => (
                <a href={"/observation_request/" + id}>
                    <Button type={"primary"}>
                        مشاهده فرم
                    </Button>
                </a>
            ),
        },
        {
            dataIndex: OBSERVATION_SUBJECT,
            title: 'موضوع رصد',
            width: 50,
            render: (observationSubject) => renderObservationSubject(observationSubject)
        },
        {
            dataIndex: TELESCOPE,
            title: 'ابزار رصد',
            width: 100,
            render: (telescope) => renderTelescope(telescope)
        },
        {
            dataIndex: OBSERVATION_STATE,
            title: 'وضعیت',
            width: 100,
            render: (observationState) => renderObservationState(observationState)
        },
        {
            dataIndex: MATERIAL_NAME,
            title: 'نام جرم',
            width: 60,
        },
        {
            dataIndex: START_DT,
            title: "تاریخ شروع رصد",
            width: 80,
            render: (_, observationRequest) => <TimeRender
                time={observationRequest[OBSERVATION_REQUEST_TIME]?.start_dt}/>
        },
        {
            dataIndex: END_DT,
            title: "تاریخ پایان رصد",
            width: 80,
            render: (_, observationRequest) => <TimeRender time={observationRequest[OBSERVATION_REQUEST_TIME]?.end_dt}/>
        },
        {
            dataIndex: OBSERVATION_PURPOSE,
            title: "هدف از رصد",
            width: 80,
            render: (observationPurpose) => renderObservationPurpose(observationPurpose)
        },
        {
            dataIndex: REASON,
            title: "علت رصد",
            width: 80,
        },
        {
            dataIndex: INSERT_DT,
            title: "تاریخ درخواست",
            width: 80,
            sorter: true,
            render: (time) => <TimeRender time={time}/>
        },

        {
            dataIndex: USER_URL,
            title: 'رصد آنلاین',
            width: 50,
            render: (userUrl) => renderSkyroom(userUrl),
        },
    ];

    return (
        <PageContainer>
            <ProTable
                columns={columns}
                rowKey={ID}
                request={async (params, sorter, filter) => {
                    let limit = params["pageSize"]
                    let current = params["current"]
                    let offset = (current - 1) * limit
                    let url = OBSERVATION_REQUEST_LIST_URL + "?offset=" + offset + "&limit=" + limit
                    if (sorter[INSERT_DT]) {
                        if (sorter[INSERT_DT] === "descend") {
                            url = url + "&ordering=" + INSERT_DT
                        } else {
                            url = url + "&ordering=-" + INSERT_DT
                        }
                    }
                    const response = await request(url)
                    if (response) {
                        return {
                            ["data"]: response["results"],
                            ["success"]: true,
                            ["total"]: response["count"]
                        }
                    }
                    return {
                        ["success"]: false
                    }
                }}
                pagination={{
                    showQuickJumper: true,
                }}
                // toolBarRender={false}
                search={false}
            />
        </PageContainer>
    )
}