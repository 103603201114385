import React, {useRef, useState} from 'react';
import '../account_page.css';
import {message, Space, Typography} from 'antd';
import {
    DATA,
    EMAIL,
    FIRST_NAME,
    FORM,
    LAST_NAME,
    METHOD,
    PHONE,
    RANDOM_KEY,
    TOKEN,
    USERNAME
} from "../../../strings/field_names";
import Captcha from "../../../components/inputs/captcha";
import ConfirmPassword from "../../../components/inputs/confirm_password";
import FormButton from "../../../components/inputs/form_button";
import RandomNumberForm from "./random_number_form";
import {request} from "../../../api/api";
import {USER_REGISTER_URL} from "../../../strings/request_urls";
import ProForm, {ProFormText} from "@ant-design/pro-form";
import * as Icons from "@ant-design/icons";


export function Condition() {
    return (
        <div className={"rtl"}>
            <Typography.Paragraph>
                این درگاه خدمات رصدخانه خیام را بر بستر اینترنت ارائه می نماید و مالکیت معنوی آن در اختیار سازمان
                فضایی ایران است.
            </Typography.Paragraph>
            <Typography.Paragraph>
                ارائه خدمات رصد آنلاین از طریق این درگاه رایگان بوده و کاربران اجازه فعالیت اقتصادی و انتفاعی در ازا
                نمایش رصد به دیگران را ندارند.
            </Typography.Paragraph>
            <Typography.Paragraph>
                ثبت نام در درگاه به منزله الزام رصدخانه خیام بر ارائه خدمات در هر شرایطی نیست.
            </Typography.Paragraph>
            <Typography.Paragraph>
                به منظور دریافت خدمت رصد آنلاین و داده از رصدخانه خیام، می بایست پس از ثبت نام در درگاه، از طریق
                پیشخوان کاربری درخواست رصد ارسال گردد.
            </Typography.Paragraph>
            <Typography.Paragraph>
                پیشنهاد می گردد ابتدا، بخش‌ های «درباره سامانه» و «آموزش کاربری درگاه» را ملاحظه بفرمایید.
            </Typography.Paragraph>
        </div>
    )
}

export default function RegisterForm() {
    const [readState, setReadState] = useState(false)
    const [randomKey, setRandomKey] = useState(null)
    const formRef = useRef();

    if (!readState) {
        return (
            <div style={{direction: "rtl"}}>
                <Condition/>
                <FormButton text={"مطالعه کردم و شرایط را قبول می کنم"} onClick={() => {
                    setReadState(true)
                }}/>
                <Space direction="vertical" size={0}>
                    <a href={"https://isao.isa.ir/"} target={"_blank"}>
                        پورتال سازمان فضایی
                    </a>
                    <a href={"/about"}>
                        درباره سامانه
                    </a>
                    <a href={"/"}>
                        آموزش کاربری درگاه
                    </a>
                </Space>
            </div>
        )
    }

    return (
        <div>
            <RandomNumberForm randomKey={randomKey} setRandomKey={setRandomKey}
                              onFinish={(response) => {
                                  localStorage.setItem(TOKEN, response[TOKEN])
                                  window.location.pathname = "/"
                              }}/>
            <ProForm
                autoFocusFirstInput={true}
                isKeyPressSubmit={true}
                formRef={formRef}
                name={"register"}
                size={"large"}
                onFinish={async (values) => {
                    const response = await request(USER_REGISTER_URL, {
                        [METHOD]: "POST",
                        [DATA]: values,
                        [FORM]: formRef.current,
                    })
                    message.success("کد اعتبار سنجی ارسال شد").then()
                    setRandomKey(response[RANDOM_KEY])
                }}
                submitter={{
                    searchConfig: {
                        submitText: 'ثبت نام',
                        resetText: 'ورود',
                    },
                    resetButtonProps: {
                        onClick: () => {
                            window.location.pathname = "/"
                        },
                        type: "danger"
                    },
                }}
            >
                <ProFormText
                    name={USERNAME}
                    fieldProps={{
                        prefix: <Icons.UserOutlined/>,
                        maxLength: 10
                    }}
                    placeholder={"کد ملی"}
                    rules={[{required: true}, {len: 10}]}
                />
                <ProFormText
                    name={EMAIL}
                    fieldProps={{
                        prefix: <Icons.MailOutlined/>,
                    }}
                    placeholder={"ایمیل"}
                    rules={[{required: true}, {type: "email", message: "فرمت ایمیل نادرست است"}]}
                />
                <ProFormText
                    name={FIRST_NAME}
                    fieldProps={{
                        maxLength: 150
                    }}
                    placeholder={"نام"}
                    rules={[{required: true}]}
                />
                <ProFormText
                    name={LAST_NAME}
                    fieldProps={{
                        maxLength: 150
                    }}
                    placeholder={"نام خانوادگی"}
                    rules={[{required: true}]}
                />
                <ProFormText
                    name={PHONE}
                    fieldProps={{
                        maxLength: 11
                    }}
                    placeholder={"شماره تلفن"}
                    rules={[{required: true}, {len: 11}]}
                />
                <ConfirmPassword name={"password"} confirm={"password_repeat"} formRef={formRef}/>
                <Captcha formRef={formRef}/>
            </ProForm>
        </div>
    )
}