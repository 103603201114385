import React from "react";
import {ProFormRadio, ProFormText, ProFormTextArea} from "@ant-design/pro-form";
import {APPARENT_VALUE, MATERIAL_TYPE, PERIOD, TLE} from "../../../strings/field_names";
import Coordinate from "../../../components/inputs/coordinate";

export default function AstronomyRequestForm({formData, disabled}) {
    return (
        <div>
            <ProFormText disabled={disabled} name={APPARENT_VALUE} label={"قدر ظاهری"}
                         placeholder={null} rules={[{required: true}]}/>
            <ProFormText disabled={disabled} name={PERIOD} label={"دوره تناوب"}
                         placeholder={null} rules={[{required: true}]}
                         tooltip={"به ساعت"}/>
            <ProFormRadio.Group
                name={MATERIAL_TYPE}
                disabled={disabled}
                label={"نوع جرم"}
                placeholder={null} rules={[{required: true}]}
                options={[
                    {
                        label: 'مدارگرد',
                        value: 'O',
                    },
                    {
                        label: 'جرم سماوی',
                        value: 'C',
                    },
                ]}
            />
            {formData[MATERIAL_TYPE] === "O" ?
                <ProFormTextArea disabled={disabled} name={TLE} label={"TLE"} placeholder={null}/>
                : null}
            {formData[MATERIAL_TYPE] === "C" ?
                <Coordinate disabled={disabled}/>
                : null}
        </div>
    )
}