import React, {useRef} from 'react';
import '../account/account_page.css';
import ConfirmPassword from "../../components/inputs/confirm_password";
import {DATA, FORM, METHOD, PASSWORD} from "../../strings/field_names";
import {request} from "../../api/api";
import {USER_CHANGE_PASSWORD_URL} from "../../strings/request_urls";
import ProForm, {ProFormText} from "@ant-design/pro-form";
import * as Icons from "@ant-design/icons";

export default function ChangePasswordForm() {
    const formRef = useRef();

    return (
        <ProForm
            autoFocusFirstInput={true}
            isKeyPressSubmit={true}
            formRef={formRef}
            name={"change_password"}
            size={"large"}
            onFinish={async (values) => {
                await request(USER_CHANGE_PASSWORD_URL, {
                    [METHOD]: "POST",
                    [DATA]: values,
                    [FORM]: formRef.current,
                })
                formRef.current?.resetFields()
            }}
            submitter={{
                searchConfig: {
                    submitText: 'تغییر گذرواژه',
                },
                resetButtonProps: {
                    style: {
                        display: "none"
                    }
                },
            }}
        >
            <ProFormText.Password
                name={PASSWORD}
                fieldProps={{
                    prefix: <Icons.LockOutlined/>,
                }}
                placeholder={"کلمه عبور"}
                rules={[{required: true}]}
            />
            <ConfirmPassword name={"new_password1"} confirm={"new_password2"} formRef={formRef}/>
        </ProForm>
    )
}