import React, {useEffect, useState} from "react";
import {ProFormText} from "@ant-design/pro-form";
import {DatePicker as DatePickerJalali, TimePicker} from "antd-jalali";

export default function JalaliDatetime({formRef, name, label, rules}) {
    const [datetime, setDatetime] = useState(null)
    const [date, setDate] = useState(null)
    const [time, setTime] = useState(null)

    useEffect(() => {
        if (!date || !time) {
            setDatetime(null)
            return
        }
        const newDatetime = date["$y"] + "-" + (date["$M"] + 1) + "-" + date["$D"] + " " + time
        setDatetime(newDatetime)
    }, [date, time])

    useEffect(() => {
        if (formRef && formRef.current) {
            formRef.current.setFieldsValue({[name]: datetime})
        }
    }, [datetime])

    return (
        <ProFormText name={name} label={label} rules={rules}>
            <DatePickerJalali onChange={(a) => {
                setDate(a)
            }}/>
            <TimePicker onChange={(a, b) => {
                setTime(b)
            }}/>
        </ProFormText>
    )
}