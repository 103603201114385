import {
    DATA,
    ERROR,
    FAIL,
    FINISH,
    FORM,
    FUNCTION,
    HANDLE,
    HEADER,
    METHOD,
    SUCCESS,
    TEXT,
    TOKEN,
    TYPE
} from "../strings/field_names";
import {message} from 'antd';
import {HTTP_400_BAD_REQUEST, HTTP_502_BAD_GATEWAY} from "../strings/status_codes";
import {setFieldsError} from "../statics/utils";

export const SERVER_URL = "/api"

export function callFunction(func, data) {
    try {
        if (typeof func === FUNCTION) {
            return func(data);
        } else {
            return func
        }
    } catch (e) {
        console.error(func, e)
    }
    return null
}

export function showMessages(result_data) {
    result_data = result_data || []
    let text = null
    for (let result of result_data) {
        if (result[TEXT] !== "") {
            message[result[TYPE]](result[TEXT]);
            text = result[TEXT]
        }
    }
    return text
}

export const get_authorization = () => ({
    "authorization": "token " + localStorage.getItem(TOKEN)
})

export const build_url = (url) => (SERVER_URL + (url || ""))

export async function request(url, options, setLoading) {
    callFunction(setLoading, true)
    options = options || {}
    const method = options[METHOD] || "GET"
    const data = options[DATA] || null
    const headers = {
        ...options[HEADER] || {},
        ...get_authorization()
    }
    const handle = options[HANDLE] || {}

    handle[HTTP_502_BAD_GATEWAY] = handle[HTTP_502_BAD_GATEWAY] || (() => {
        message.error("خطای ارتباط با سرور")
    })

    url = options.url || build_url(url)

    headers["Accept"] = headers["Accept"] || "application/json"
    headers["Content-Type"] = headers["Content-Type"] || "application/json; charset=UTF-8"
    let json_response = headers["Accept"] === "application/json"
    let response = null
    let responseStatus = 0
    try {
        response = await fetch(url, {
            method: method,
            body: data ? JSON.stringify(data) : null,
            headers: headers,
            credentials: "omit",
            // credentials: 'include',
            // referrerPolicy: 'no-referrer',
        })
        responseStatus = response.status

        // if (handleData[DOWNLOAD]) {
        //     fileData = await response.blob()
        // } else {
        // }
    } catch (e) {
        message.error("خطای اتصال به سرور")
        callFunction(setLoading, false)
        await callFunction(handle[FAIL], null)
        throw null
    }
    try {
        if (json_response) {
            response = await response.json()
            showMessages(response["_messages"])
        }
    } catch (e) {

    }

    callFunction(setLoading, false)
    await callFunction(handle[responseStatus], response)
    await callFunction(handle[FINISH], response)
    if (responseStatus >= 300 || responseStatus < 200) {
        await callFunction(handle[ERROR], response)
        if (responseStatus === HTTP_400_BAD_REQUEST && options[FORM]) {
            // options[FORM]?.setFields()
            setFieldsError(response, options[FORM]?.setFields)
        }
        throw response
    } else {
        await callFunction(handle[SUCCESS], response)
        return response
    }
}
