import {callFunction, request} from "./api";
import {ACTION_TYPE, DATA, DELETE, FORM, METHOD, STATE, TOKEN, UPDATE, USER} from "../strings/field_names";
import {
    USER_ACCOUNT_INFO_URL,
    USER_CAPTCHA_URL,
    USER_CHANGE_PASSWORD_URL,
    USER_INFO_URL,
    USER_LOGIN_URL,
    USER_LOGOUT_URL,
    USER_REGISTER_URL
} from "../strings/request_urls";
import {message} from "antd";


export function userChangePassword(form, values, setLoading, onFinish) {

}

export function userLogout() {
    request(USER_LOGOUT_URL, {[METHOD]: "POST"}).then(() => {
        localStorage.removeItem(TOKEN)
        window.location.pathname = "/"
    }).catch(() => {
        localStorage.removeItem(TOKEN)
        window.location.pathname = "/"
    })
}


export function userInfo(setUser) {
    request(USER_INFO_URL, {}).then((data) => {
        callFunction(setUser, {
            [ACTION_TYPE]: UPDATE,
            [STATE]: data
        })
    }).catch((response) => {
        if (response) {
            callFunction(setUser, {
                [ACTION_TYPE]: DELETE,
            })
        }
    })
}

export function userAccountInfo(setAccountInfo) {
    request(USER_ACCOUNT_INFO_URL, {}).then((accountInfo) => {
        setAccountInfo(accountInfo)
    }).catch((response) => {
        if (response) {
            localStorage.removeItem(TOKEN)
            localStorage.removeItem(USER)
            setAccountInfo({})
        }
    })
}

