import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import AccountPage from "./pages/account/account_page";
import {message} from "antd";
import DashboardPage from "./pages/dashboard/dashboard_page";
import '@ant-design/pro-utils/dist/utils.css';
import '@ant-design/pro-card/dist/card.css';
import '@ant-design/pro-field/dist/field.css';
import '@ant-design/pro-form/dist/form.css';
import '@ant-design/pro-table/dist/table.css';
import '@ant-design/pro-layout/dist/layout.css';
import './custom_layout.css';
import {ACCOUNT, TOKEN} from "./strings/field_names";
import {getDataFromStorage, UserContext} from "./components/contexts/user_context";
import LoadingPage from "./pages/loading_page";
import {userAccountInfo} from "./api/user_api";


message.config({
    duration: 5,
    rtl: true,
});


function App() {
    const [, updateUser] = useContext(UserContext)
    const [token,] = useState(localStorage.getItem(TOKEN))
    const [accountInfo, setAccountInfo] = useState(getDataFromStorage(ACCOUNT))

    useEffect(() => {
        if (accountInfo) {
            localStorage.setItem(ACCOUNT, JSON.stringify(accountInfo))
        }
    }, [accountInfo])

    useEffect(() => {
        updateUser()
        if (!token) {
            setAccountInfo({})
            return
        }
        userAccountInfo(setAccountInfo)
    }, [])

    if (!accountInfo) {
        return <LoadingPage/>
    }

    return (
        <div className={"app_container"}>
            <Router>
                <Routes>
                    {
                        token && accountInfo["dashboard_page"] ?
                            <Route path="/*" element={<DashboardPage/>}/>
                            :
                            <Route path="/*" element={<AccountPage accountInfo={accountInfo}/>}/>
                    }
                </Routes>
            </Router>
        </div>
    );
}

export default App;
