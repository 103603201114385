import {callFunction, request} from "./api";
import {DATA, METHOD, OBSERVATION_STATE} from "../strings/field_names";
import {OBSERVATION_REQUEST_LIST_URL} from "../strings/request_urls";

export async function observationAction(observationRequestId, state, setLoading) {
    return await request(OBSERVATION_REQUEST_LIST_URL + observationRequestId + "/action/", {
        [METHOD]: "POST",
        [DATA]: {
            [OBSERVATION_STATE]: state
        },
    }, setLoading)
}