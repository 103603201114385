import * as Icons from '@ant-design/icons';


export const EmailIcon = Icons.MailOutlined
export const CodeIcon = Icons.QrcodeOutlined
export const ProfileIcon = Icons.ProfileOutlined
export const FormOutlined = Icons.FormOutlined
export const GroupIcon = Icons.GroupOutlined
export const VerifyIcon = Icons.CheckOutlined
export const AppStoreIcon = Icons.AppstoreAddOutlined
export const GitIcon = Icons.GitlabOutlined
export const AdminIcon = Icons.PieChartOutlined
export const ActivityIcon = Icons.LineChartOutlined
export const SessionIcon = Icons.InteractionOutlined
export const InboxMessageIcon = Icons.InboxOutlined
export const MessageIcon = Icons.MailOutlined
export const SMSIcon = Icons.MessageOutlined
export const ServerIcon = Icons.CloudServerOutlined
export const IpIcon = Icons.CloudServerOutlined
export const DashboardIcon = Icons.DashboardOutlined
export const PasswordIcon = Icons.LockOutlined
export const PersonListIcon = Icons.UserOutlined
export const InfoCircleIcon = Icons.InfoCircleOutlined
export const UserIcon = Icons.UserOutlined
export const NotificationIcon = Icons.NotificationOutlined
export const ChangeThemeIcon = Icons.HighlightOutlined
export const ChangeBackgroundColorIcon = Icons.BgColorsOutlined
export const ChangeBackgroundAnimationIcon = Icons.DeploymentUnitOutlined
export const SearchIcon = Icons.SearchOutlined
export const DangerIcon = Icons.ExclamationCircleOutlined
export const PlusIcon = Icons.PlusOutlined
export const LogoutIcon = Icons.LogoutOutlined
export const DownIcon = Icons.DownOutlined
export const MenuIcon = Icons.MenuOutlined
export const LoginIcon = Icons.UserOutlined
export const RegisterIcon = Icons.UserAddOutlined
export const ForgotPasswordIcon = Icons.BulbOutlined
export const EditIcon = Icons.EditOutlined
export const EyeIcon = Icons.EyeOutlined
export const DownloadIcon = Icons.DownloadOutlined
export const ShopIcon = Icons.ShopOutlined
export const DeleteIcon = Icons.DeleteOutlined
export const DatabaseIcon = Icons.DatabaseOutlined
export const ReadIcon = Icons.ReadOutlined
export const ReloadIcon = Icons.ReloadOutlined
export const CloseIcon = Icons.CloseOutlined
export const OriginVersionIcon = Icons.CloudDownloadOutlined
export const DestinationVersionIcon = Icons.CloudUploadOutlined
export const DiffIcon = Icons.DiffOutlined