import {message, Tag, Tooltip} from "antd";
import persianDate from "persian-date/dist/persian-date"
import React from "react";
import {STRING} from "../strings/field_names";
import {callFunction} from "../api/api";
// import {SELF_PERSON_PERMISSION} from "../Strings/PermissionNames";
// https://github.com/fingerpich/jalali-moment#install
// http://babakhani.github.io/PersianWebToolkit/beta/persian-date/
// import moment from 'jalali-moment';

export const EMAIL_CHECK = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const IP_CHECK = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
// export const COMPANY_EMAIL_CHECK = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@yaftar.ir$/;
export const NUMBER_CHECK = /^-?(0|[0-9]*)(\.[0-9]*)?$/;
// export const APP_DATABASE = "APP_DATABASE";


export const joinUrl = (base, url = "", slashEnd = false) => {
    if (base.substr(-1) === "/") {
        base = base.slice(0, -1)
    }
    if (url.substr(0, 1) === "/") {
        url = url.slice(1)
    }
    let result = base + "/" + url
    if (slashEnd) {
        if (result.substr(-1) !== "/") {
            result += "/"
        }
    } else {
        if (result.substr(-1) === "/") {
            result = result.slice(0, -1)
        }
    }

    return result
}

export const beforeUploadImage = (file) => {
    // console.log(file);
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('فرمت عکس نادرست است.').then();
    }
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
        message.error('حجم عکس باید کمتر از ۴ مگابایت باشد.').then();
    }
    return isJpgOrPng && isLt2M;
};


// export const beforeUploadImageAdvance = (file) => {
//     return new Promise(resolve => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file);
//         reader.onload = () => {
//
//         };
//     })
// }

//
// export const checkPermissions = (permissionList, needPermission) => {
//     if (!permissionList) {
//         return false
//     }
//     if (!needPermission) {
//         return true
//     }
//     if (typeof needPermission === STRING) {
//         needPermission = [needPermission]
//     }
//     for (let need of needPermission) {
//         if (permissionList.includes(need)) {
//             return true
//         }
//     }
//     return permissionList.includes(IS_SUPERUSER) && !needPermission.includes(SELF_PERSON_PERMISSION);
// };

export const hasListId = (list, id) => {
    if (!list) {
        return true
    }
    for (let item of list) {
        if (item.id === id) {
            return true
        }
    }
    return false
};

export const setFieldsError = (data, setFields) => {
    const newFields = []
    for (let field of Object.keys(data)) {
        let errors = data[field]
        if (typeof errors === STRING) {
            errors = [errors]
        }
        newFields.push({
            "name": field,
            "errors": errors
        })
    }
    callFunction(setFields, newFields)
}

const EN_TO_FA_MAP = {
    "0": "۰",
    "1": "۱",
    "2": "۲",
    "3": "۳",
    "4": "۴",
    "5": "۵",
    "6": "۶",
    "7": "۷",
    "8": "۸",
    "9": "۹",
}

export const enToFa = (string) => {
    for (const [key, value] of Object.entries(EN_TO_FA_MAP)) {
        string = string.replaceAll(key, value)
    }
    return string
}


export const convertToJalali = (time, format) => {
    // https://www.npmjs.com/package/antd-jalali
    // http://babakhani.github.io/PersianWebToolkit/beta/persian-date/
    if (!time) {
        time = new Date();
    } else if (typeof (time) !== "object") {
        time = time * 1000;
    }
    if (!format) {
        format = "dddd DD MMMM YYYY HH:mm:ss"
    }
    // noinspection JSUnresolvedFunction, JSPotentiallyInvalidConstructorUsage
    return (new persianDate(time).format(format))
};

export const convertToSmallJalali = (time) => {
    if (!time) {
        return ""
    }
    return convertToJalali(time, "YY/M/D HH:mm")
};

export const convertToEpoch = (time) => {
    return convertToJalali(time, "X")
};

export const convertToDate = (time) => {
    return convertToJalali(time, "dddd DD MMMM YYYY")
};

export const TimeRender = ({time}) => (
    <Tooltip title={convertToJalali(time)}>
        {convertToSmallJalali(time)}
    </Tooltip>
)

export function convertObjToBase64(data) {
    return btoa(JSON.stringify(data))
}

export function convertBase64ToObj(data) {
    if (!data) {
        return null
    }
    try {
        return JSON.parse(atob(data))
    } catch (e) {
        console.error(e)
        return null
    }
}

// export function convertIntToColor(color) {
// noinspection JSStringConcatenationToES6Template
// return "#" + color.toString(16).padStart(6, '0');
// }

export function getField(obj, keys = [], def = null) {
    if (!obj) {
        return def
    }
    let current = obj
    for (let key of keys) {
        current = current[key]
        if (!current) {
            return def
        }
    }
    return current
}

export const hashCode = (str) => { // java String#hashCode
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
};

export const intToRGB = (i) => {
    let c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
};

export const convertToColor = (str) => {
    return "#" + intToRGB(hashCode(str)).toString()
};

export const getWithNewLine = (str) => (
    <>
        {str.split(/(?:\r\n|\r|\n)/g).map((text, index) => {
            return <span key={index}>
                {text}
                <br/>
            </span>
        })}
    </>
)

export const getTreeData = (data) => {
    if (!data) {
        return
    }
    return Object.keys(data).map((d) => {
        return {
            title: data[d],
            key: d,
        }
    })
}

export const download = (fileData, filename) => {
    let file = window.URL.createObjectURL(fileData)
    let element = document.createElement("a")
    element.setAttribute('href', file)
    element.setAttribute("download", filename)
    element.click()
}

export const fetchData = (verify) => {
    if (verify) {
        return (<Tag color="green">{"تایید شده"}</Tag>)
    } else {
        return (<Tag color="red">{"تایید نشده"}</Tag>)
    }
}

// export const getImage = (fileData, filename) => {
//     let file = window.URL.createObjectURL(fileData)
//     let element = document.createElement("img")
//     element.setAttribute('src', file)
//     element.setAttribute("alt", filename)
//     return element
// }

// export async function DownloadData(url, setLoading) {
//     console.debug("Request", url);
//     callFunction(setLoading, true)
//     let error = null
//     let headers = new Headers()
//     headers.append('Accept', '*')
//
//     for (let i = DEFAULT_RETRY; i >= 0; i--) {
//         if (i === 0) {
//             callFunction(setLoading, false)
//             throw error
//         }
//         try {
//             const response = await fetch(joinUrl(SERVER_MEDIA, url), {
//                 method: 'GET',
//                 // body: JSON.stringify(jsonData),
//                 headers: headers,
//                 credentials: 'same-origin',
//                 // mode: "no-cors",
//                 referrerPolicy: 'no-referrer',
//             })
//             callFunction(setLoading, false)
//             // console.log(response)
//             const responseText = await response.text()
//             // console.log(responseText)
//             console.debug("Response", url);
//             return responseText
//         } catch (e) {
//             error = e
//             console.debug(i, e)
//         }
//     }
// }

