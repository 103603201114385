import React from "react";
import {ProFormRadio, ProFormSwitch, ProFormText} from "@ant-design/pro-form";
import {MATERIAL_TYPE, NEED_SPECIAL_CONDITION, SPECIAL_CONDITION} from "../../../strings/field_names";
import TelescopeFilter from "../../../components/inputs/telescope_filter";

export default function SolarSystemRequest({formData, telescope, disabled}) {
    return (
        <div>
            <ProFormRadio.Group
                disabled={disabled}
                name={MATERIAL_TYPE}
                label={"نوع جرم"}
                placeholder={null} rules={[{required: true}]}
                options={[
                    {
                        label: 'سیاره',
                        value: 'L',
                    },
                    {
                        label: 'سیارک',
                        value: 'A',
                    },
                    {
                        label: 'دنباله دار',
                        value: 'P',
                    },
                    {
                        label: 'قمر',
                        value: 'G',
                    },
                ]}
            />

            <ProFormSwitch name={NEED_SPECIAL_CONDITION} label={"آیا پدیده خاصی مدنظر است؟"}
                           disabled={disabled}
                           checkedChildren={"بله"} unCheckedChildren={"خیر"}/>
            {formData[NEED_SPECIAL_CONDITION] ?
                <ProFormText disabled={disabled} name={SPECIAL_CONDITION} label={"پدیده خاص"} placeholder={null}/>
                : null}
            <TelescopeFilter telescope={telescope} disabled={disabled}/>
        </div>
    )
}