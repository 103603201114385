import {Descriptions} from "antd";
import EditableAvatar from "../../components/content/editable_avatar";
import {AVATAR, EMAIL, FIRST_NAME, LAST_NAME, PHONE, USERNAME} from "../../strings/field_names";
import React, {useCallback, useContext} from "react";
import {UserContext} from "../../components/contexts/user_context";
import EditableText from "../../components/content/editable_text";
import {USER_INFO_URL} from "../../strings/request_urls";

export default function UserDetail() {
    const [user, updateUser] = useContext(UserContext)

    const onFinish = useCallback(() => {
        updateUser()
    }, [updateUser])

    return (
        <Descriptions bordered>
            <Descriptions.Item label={"تصویر"}>
                <EditableAvatar name={AVATAR} detail={user} action={USER_INFO_URL} onFinish={onFinish}/>
            </Descriptions.Item>
            <Descriptions.Item label={"کد ملی"}>{user[USERNAME]}</Descriptions.Item>
            <Descriptions.Item label={"نام"}>
                <EditableText detail={user} name={FIRST_NAME} action={USER_INFO_URL} onFinish={onFinish}/>
            </Descriptions.Item>
            <Descriptions.Item label={"نام خانوادگی"}>
                <EditableText detail={user} name={LAST_NAME} action={USER_INFO_URL} onFinish={onFinish}/>
            </Descriptions.Item>
            <Descriptions.Item label={"ایمیل"}>{user[EMAIL]}</Descriptions.Item>
            <Descriptions.Item label={"شماره تلفن"}>
                {user[PHONE]}
            </Descriptions.Item>
        </Descriptions>
    )
}