import React, {useState} from "react";
import {PageContainer} from "@ant-design/pro-layout";
import ProForm from '@ant-design/pro-form';
import ProCard from '@ant-design/pro-card';
import {Modal, Steps} from 'antd';
import ObservationRequestTime from "./observation_request_times";
import ObservationSubjectForm from "./steps/observation_subject_form";
import TelescopeForm from "./steps/telescope_form";
import ObservationRequestForm from "./steps/observation_request_form";
import {observationAction} from "../../api/observation_api";
import {ID} from "../../strings/field_names";
import {callFunction} from "../../api/api";
import {Condition} from "../account/forms/register_form";


export function completeRequest(observationRequest, onFinish) {
    Modal.confirm({
        direction: "rtl",
        title: 'شرایط درخواست رصد',
        // icon: <ExclamationCircleOutlined/>,
        okText: "شرایط را می‌پذیرم",
        content: <Condition/>,
        onOk() {
            return new Promise((resolve, reject) => {
                observationAction(observationRequest, 2).then((response) => {
                    resolve()
                    callFunction(onFinish, response)
                }).catch(reject)
            });
        },
        onCancel() {
        },
    });
}

export default function ObservationRequest() {
    const [current, setCurrent] = useState(0);
    const [observationSubject, setObservationSubject] = useState(null)
    const [telescope, setTelescope] = useState(null)
    const [observationRequest, setObservationRequest] = useState(null)

    return (
        <PageContainer>
            <div className={"mirror"}>
                <Steps current={current}>
                    <Steps.Step title={"موضوع رصد"}/>
                    <Steps.Step title={"ابزار رصد"}/>
                    <Steps.Step title={"اطلاعات بیشتر"}/>
                    <Steps.Step title={"انتخاب زمان رصد"}/>
                </Steps>
                <br/>
                <div style={{maxWidth: 800, margin: "auto", direction: "rtl", textAlign: "right"}}>
                    {current === 0 ?
                        <ObservationSubjectForm
                            onFinish={(observationSubject) => {
                                setObservationSubject(observationSubject)
                                setCurrent(1)
                            }
                            }/>
                        : null}
                    {current === 1 ?
                        <TelescopeForm observationSubject={observationSubject}
                                       onFinish={(telescope) => {
                                           setTelescope(telescope)
                                           setCurrent(2)
                                       }} onCancel={() => setCurrent(0)}/>
                        : null}
                    {current === 2 ?
                        <ObservationRequestForm observationRequest={observationRequest}
                                                observationSubject={observationSubject}
                                                telescope={telescope}
                                                onFinish={(data) => {
                                                    setObservationRequest(data[ID])
                                                    setCurrent(3)
                                                }} onCancel={() => setCurrent(1)}/>
                        : null}
                    {current === 3 && observationRequest ?
                        <ProForm
                            name={"timing"}
                            onFinish={async () => {
                                completeRequest(observationRequest, () => {
                                    window.location.pathname = "/observation_request_list"
                                })
                            }}
                            submitter={{
                                searchConfig: {
                                    submitText: 'تایید',
                                    resetText: 'بازگشت',
                                },
                                resetButtonProps: {
                                    onClick: () => setCurrent(2),
                                },
                            }}
                        >
                            <ObservationRequestTime
                                editable={true}
                                observationRequest={observationRequest}/>
                        </ProForm>
                        : null}
                </div>
            </div>
        </PageContainer>
    )
}