import React from 'react';
import './account_page.css';
import {Typography} from 'antd';
import FormButton from "../../components/inputs/form_button";

export default function AboutPage() {
    return (
        <div className={"rtl"}>
            <Typography.Title level={3}>
                درباره سامانه
            </Typography.Title>
            <Typography.Paragraph>
                درگاه آنلاین رصد آسمان به منظور ارائه خدمات رصدخانه خیام سازمان فضایی ایران بر بستر اینترنت و در راستای
                توسعه عدالت علمی و ایجاد دسترسی عمومی به زیرساخت های دولتی ایجاد شده است. منجمان، پژوهشگران، مروجان علم
                و علاقمندان به نجوم می توانند از طریق این درگاه به رصد آنلاین جهان هستی بپردازند و داده های قابل دریافت
                از رصدخانه خیام را اخذ نمایند. خدمات قابل ارائه از این طریق عبارتند از:
            </Typography.Paragraph>
            <Typography.Paragraph>
                <ul>
                    <li>
                        رصد آنلاین اجرام عمق آسمان، خورشید و اجرام منظومه شمسی به منظور ترویج و توسعه نجوم و علوم فضایی
                    </li>
                    <li>
                        داده گیری از ستارگان و اجرام آسمانی به منظور نورسنجی و اخترسنجی آنها
                    </li>
                    <li>
                        ثبت تصاویر اجرام آسمانی در طول موج مرئی و فلیترهای گوناگون به منظور فعالیت های پژوهشی و ترویجی
                    </li>
                </ul>
            </Typography.Paragraph>
            <Typography.Paragraph>
                به منظور بهره مندی از این خدمات تنها کافی است در درگاه ثبت نام کنید و درخواست خود برای رصد را ارسال
                نمایید. پس از بررسی و تائید درخواست شما، دسترسی فراهم می گردد تا بتوانید به صورت آنلاین فرآیند داده گیری
                را مشاهده کنید و به رصد زیبایی های جهان هستی بنشینید و داده های مورد نیاز خود را دریافت نمایید.
            </Typography.Paragraph>
            <FormButton type={"default"} text={"بازگشت"} href={"/"}/>
        </div>
    )
}