import {MESSAGE, OBSERVATION_SUBJECT, REQUIRED} from "../../../strings/field_names";
import {callFunction, request} from "../../../api/api";
import {Alert} from "antd";
import ProForm, {ProFormRadio} from "@ant-design/pro-form";
import React, {useRef} from "react";
import {OBSERVATION_SUBJECTS_LIST_URL} from "../../../strings/request_urls";

export default function ObservationSubjectForm({onFinish}) {
    const formRef = useRef();

    return (
        <ProForm
            formRef={formRef}
            name={OBSERVATION_SUBJECT}
            onFinish={(values) => {
                callFunction(onFinish, values[OBSERVATION_SUBJECT])
            }}
            submitter={{
                searchConfig: {
                    submitText: 'تایید',
                },
                resetButtonProps: {
                    style: {
                        display: "none"
                    }
                },
            }}
        >
            <Alert message="موضوع رصد خود را انتخاب نمایید" type="info"/>
            <br/>
            <ProFormRadio.Group
                name={OBSERVATION_SUBJECT}
                layout="vertical"
                label={"موضوع رصد"}
                request={async () => {
                    return await request(OBSERVATION_SUBJECTS_LIST_URL)
                }}
                rules={[{[REQUIRED]: true, [MESSAGE]: 'لطفا موضوع رصد را انتخاب نمایید'}]}
            />
        </ProForm>
    )
}