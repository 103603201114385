import React from 'react';
import './account_page.css';
import {Typography} from 'antd';

export default function UserActiveError() {
    return (
        <div className={"rtl"}>
            <Typography.Title level={4}>
                حساب کاربری غیر فعال است. بعد از بررسی و فعال شدن اطلاع داده می‌شود.
            </Typography.Title>
        </div>
    )
}