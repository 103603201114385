export const STATIC_CITY_URL = "/v1/static/city/"
export const STATIC_EDUCATION_GRADE_URL = "/v1/static/education_grade/"
export const STATIC_EDUCATION_FIELD_URL = "/v1/static/education_field/"
export const STATIC_PROVINCE_URL = "/v1/static/province/"

export const USER_CAPTCHA_URL = "/v1/user/captcha/"
export const USER_LOGOUT_URL = "/v1/user/logout/"
export const USER_LOGIN_URL = "/v1/user/login/"
export const USER_FORGOT_URL = "/v1/user/forgot/"
export const USER_FORGOT_CHANGE_URL = "/v1/user/forgot/change/"
export const USER_REGISTER_URL = "/v1/user/register/"
export const USER_RANDOM_NUMBER_URL = "/v1/user/random_number/"
export const USER_INFO_URL = "/v1/user/info/"
export const USER_DETAIL_URL = "/v1/user/detail/"
export const USER_ACCOUNT_INFO_URL = "/v1/user/account_info/"
export const USER_CHANGE_PASSWORD_URL = "/v1/user/change_password/"


export const OBSERVATION_REQUEST_LIST_URL = "/v1/observation/observation_request/"
export const OBSERVATION_SUBJECTS_LIST_URL = "/v1/observation/subjects/"
export const OBSERVATION_TELESCOPES_LIST_URL = "/v1/observation/telescopes/"
export const OBSERVATION_TELESCOPE_DETAIL_URL = "/v1/observation/telescope/"
export const OBSERVATION_REQUEST_URL = "/v1/observation/request/"
export const OBSERVATION_PURPOSES_LIST_URL = "/v1/observation/purposes/"