import React, {createContext, useCallback, useEffect, useReducer} from "react";
import {ACTION_TYPE, DELETE, STATE, TOKEN, UPDATE, USER} from "../../strings/field_names";
import {callFunction} from "../../api/api";
import {userInfo} from "../../api/user_api";

export const UserContext = createContext({});

export const UserReducer = (state, action) => {
    action = action || {}
    let newState = state
    switch (action[ACTION_TYPE]) {
        case UPDATE:
            newState = {...newState, ...action[STATE]}
            break
        case DELETE:
            localStorage.removeItem(TOKEN)
            newState = {}
            break
        default:
            break
    }
    return newState
};

export const getDataFromStorage = (name) => {
    const localData = localStorage.getItem(name);
    try {
        return localData ? JSON.parse(localData) : null
    } catch (e) {
        console.error(e)
        return null
    }
}

export default function UserProvider({children}) {
    const [user, setUser] = useReducer(UserReducer, getDataFromStorage(USER) || {})

    useEffect(() => {
        localStorage.setItem(USER, JSON.stringify(user))
    }, [user])

    const updateUser = useCallback(() => {
        const token = localStorage.getItem(TOKEN)
        if (!token) {
            callFunction(setUser, {
                [ACTION_TYPE]: DELETE,
            })
            return
        }
        userInfo(setUser)
    }, [])

    return (
        <UserContext.Provider value={[user, updateUser]}>
            {children}
        </UserContext.Provider>
    );
}