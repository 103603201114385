import React, {useState} from "react";
import UserDetail from "./user_detail";
import {PageContainer} from "@ant-design/pro-layout";
import UserDetailForm from "../account/forms/user_detail_form";
import ChangePasswordForm from "./change_password_form";

export default function UserProfile() {
    const [tab, setTab] = useState('detail')
    return (
        <PageContainer
            content={<UserDetail/>}
            tabList={[
                {
                    tab: 'ویرایش مشخصات',
                    key: 'detail',
                },
                {
                    tab: 'ویرایش کلمه عبور',
                    key: 'password',
                },
            ]}
            tabActiveKey={tab}
            onTabChange={(newTab) => setTab(newTab)}
        >
            <div className={"form"}>
                {tab === "detail"? <UserDetailForm/>: null}
                {tab === "password"? <ChangePasswordForm password={true}/>: null}
            </div>
        </PageContainer>
    )
}