import {Button, Form} from 'antd';
import React from "react";

export default function FormButton({noStyle = false, text, href, type = "primary", loading, disabled, onClick, danger, htmlType, extraUrl, extraText}) {
    return (
        <Form.Item noStyle={noStyle}>
            <a href={href}>
                <Button type={type} style={{width: '100%'}}
                        htmlType={htmlType}
                        danger={danger}
                        loading={loading}
                        disabled={disabled}
                        onClick={onClick}>
                    {text}
                </Button>
            </a>
            {extraUrl ?
                <a href={extraUrl}>
                    {extraText}
                </a> :
                null
            }

        </Form.Item>
    );

}