import React, {useRef, useState} from 'react';
import '../account_page.css';
import {CAPTCHA_ANSWER, CAPTCHA_KEY, DATA, FORM, METHOD, RANDOM_KEY, USERNAME} from "../../../strings/field_names";
import Captcha from "../../../components/inputs/captcha";
import RandomNumberForm from "./random_number_form";
import ProForm, {ProFormText} from "@ant-design/pro-form";
import * as Icons from "@ant-design/icons";
import {request} from "../../../api/api";
import {message} from "antd";
import {USER_FORGOT_CHANGE_URL, USER_FORGOT_URL} from "../../../strings/request_urls";
import ConfirmPassword from "../../../components/inputs/confirm_password";

export default function ForgotPasswordForm() {
    const [extraData, setExtraData] = useState(null)
    const [randomKey, setRandomKey] = useState(null)
    const formRef = useRef();

    return (
        <div>
            <RandomNumberForm randomKey={randomKey} setRandomKey={setRandomKey}
                              onFinish={(response) => {
                                  setExtraData(response)
                              }}/>
            {!extraData ? <ProForm
                autoFocusFirstInput={true}
                isKeyPressSubmit={true}
                formRef={formRef}
                name={"forgot"}
                size={"large"}
                onFinish={async (values) => {
                    const response = await request(USER_FORGOT_URL, {
                        [METHOD]: "POST",
                        [DATA]: values,
                        [FORM]: formRef.current,
                    })
                    message.success("کد اعتبار سنجی ارسال شد").then()
                    setRandomKey(response[RANDOM_KEY])
                }}
                submitter={{
                    searchConfig: {
                        submitText: 'فراموشی گذرواژه',
                        resetText: 'بازگشت',
                    },
                    resetButtonProps: {
                        onClick: () => {
                            window.location.pathname = "/"
                        },
                    },
                }}
            >
                <ProFormText
                    name={USERNAME}
                    fieldProps={{
                        prefix: <Icons.UserOutlined/>,
                        maxLength: 10
                    }}
                    placeholder={"کد ملی"}
                    rules={[{required: true}, {len: 10}]}
                />
                <Captcha name={CAPTCHA_ANSWER} keyName={CAPTCHA_KEY} formRef={formRef}/>
            </ProForm> : <ProForm
                isKeyPressSubmit={true}
                formRef={formRef}
                name={"change_password"}
                size={"large"}
                onFinish={async (values) => {
                    const sendData = {
                        ...values,
                        ...extraData
                    }
                    await request(USER_FORGOT_CHANGE_URL, {
                        [METHOD]: "POST",
                        [DATA]: sendData,
                        [FORM]: formRef.current,
                    })
                    message.success("کلمه عبور با موفقیت تغییر کرد").then()
                    window.location.pathname = "/"
                }}
                submitter={{
                    searchConfig: {
                        submitText: 'تغییر گذرواژه',
                        resetText: 'بازگشت',
                    },
                    resetButtonProps: {
                        onClick: () => {
                            setExtraData(null)
                        },
                    },
                }}
            >
                <ConfirmPassword name={"new_password1"} confirm={"new_password2"} formRef={formRef}/>
            </ProForm>}

        </div>
    )
}