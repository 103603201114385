import {
    COORDINATE_LATITUDE_SIGN,
    DATA,
    DESCRIPTION,
    FORM, LABEL,
    MATERIAL_DETAIL_NAME,
    MATERIAL_NAME,
    METHOD,
    NAME,
    NEED_DETAIL,
    OBSERVATION_PURPOSE,
    PURPOSE_DETAIL,
    REASON,
    TELESCOPE
} from "../../../strings/field_names";
import {callFunction, request} from "../../../api/api";
import {message} from "antd";
import ProForm, {ProFormRadio, ProFormText} from "@ant-design/pro-form";
import React, {useRef, useState} from "react";
import PhotometryRequestForm from "../subjects/photometry_request_form";
import AstronomyRequestForm from "../subjects/astronomy_request_form";
import SpaceRequestForm from "../subjects/space_request_form";
import SolarSystemRequestForm from "../subjects/solar_system_request_form";
import {OBSERVATION_PURPOSES_LIST_URL, OBSERVATION_REQUEST_URL} from "../../../strings/request_urls";

const OBSERVATION_SUBJECT_ID = {
    PHOTOMETRY: 1,
    ASTRONOMY: 2,
    SPACE: 3,
    SOLAR: 4,
    SUN: 5
}

export default function ObservationRequestForm({observationRequest, observationSubject, telescope, onFinish, onCancel}) {
    const formRef = useRef();
    const [observationPurposes, setObservationPurposes] = useState([])
    const [formData, setFormData] = useState({})

    if (!observationSubject || !telescope) {
        return null
    }
    const disabled = !onFinish

    const ObservationDetail = {
        [OBSERVATION_SUBJECT_ID.PHOTOMETRY]: <PhotometryRequestForm telescope={telescope} disabled={disabled}/>,
        [OBSERVATION_SUBJECT_ID.ASTRONOMY]: <AstronomyRequestForm formData={formData} disabled={disabled}/>,
        [OBSERVATION_SUBJECT_ID.SPACE]: <SpaceRequestForm telescope={telescope} disabled={disabled}/>,
        [OBSERVATION_SUBJECT_ID.SOLAR]: <SolarSystemRequestForm formData={formData} telescope={telescope}
                                                                disabled={disabled}/>,
    }
    const observationPurpose = observationPurposes.find((observationPurpose) => observationPurpose.id === formData[OBSERVATION_PURPOSE])

    return (
        <ProForm
            formRef={formRef}
            name={"extra_data"}
            onValuesChange={(_, values) => {
                setFormData(values)
            }}
            onFinish={async (values) => {
                if (!onFinish) {
                    message.error("درخواست قابل تغییر نیست")
                    return
                }
                const data = {
                    [TELESCOPE]: telescope,
                    ...values
                }
                let url
                if (observationRequest) {
                    url = OBSERVATION_REQUEST_URL + observationSubject + "/" + observationRequest + "/"
                } else {
                    url = OBSERVATION_REQUEST_URL + observationSubject + "/"
                }
                const response = await request(url, {
                    [METHOD]: observationRequest ? "PUT" : "POST",
                    [DATA]: data,
                    [FORM]: formRef.current,
                })
                if (response) {
                    if (observationRequest) {
                        message.success("درخواست رصد با موفقیت تغییر یافت")
                    } else {
                        message.success("درخواست رصد با موفقیت ثبت شد")
                    }
                    callFunction(onFinish, response)
                }
            }}
            request={async () => {
                let response = {
                    [COORDINATE_LATITUDE_SIGN]: 1,
                }
                if (observationRequest) {
                    try {
                        response = await request(OBSERVATION_REQUEST_URL + observationSubject + "/" + observationRequest + "/")
                    } catch (e) {
                    }
                }
                setFormData(response)
                return response
            }}
            submitter={{
                searchConfig: {
                    resetText: 'بازگشت',
                    submitText: 'تایید',
                },
                submitButtonProps: {
                    style: onFinish ? {} : {
                        display: "none"
                    }
                },
                resetButtonProps: {
                    onClick: onCancel,
                    style: observationRequest ? {
                        display: "none"
                    } : {}
                },
            }}>

            {observationSubject !== OBSERVATION_SUBJECT_ID.SUN ?
                <>
                    <ProFormText name={MATERIAL_NAME} label={"نام جرم"}
                                 disabled={disabled}
                                 placeholder={null} rules={[{required: true}]}
                                 tooltip={"نام اجرام ستاره ای طبق استاندارهای, GSC"}/>
                    <ProFormText name={MATERIAL_DETAIL_NAME} label={"چنانچه این جرم نام مشخصی دارد ذکر کنید"}
                                 disabled={disabled}
                                 placeholder={null}/>
                </> : null}

            {ObservationDetail[observationSubject]}

            <ProFormRadio.Group
                name={OBSERVATION_PURPOSE}
                label={"هدف از رصد"}
                disabled={disabled}
                request={async () => {
                    const response = await request(OBSERVATION_PURPOSES_LIST_URL)
                    setObservationPurposes(response)
                    return response
                }}
                rules={[{required: true, message: 'لطفا هدف رصد را انتخاب نمایید'}]}
            />
            {observationPurpose && observationPurpose[NEED_DETAIL] ?
                <ProFormText name={PURPOSE_DETAIL} label={"جزئیات مورد نظر برای " + observationPurpose[LABEL]}
                             disabled={disabled}
                             rules={[{required: true}]} placeholder={null}/>
                : null}

            <ProFormText name={REASON} label={"لطفا دلیل انجام این رصد را عنوان کنید"}
                         disabled={disabled}
                         rules={[{required: true}]} placeholder={null}/>
            <ProFormText name={DESCRIPTION} label={"توضیحات"} placeholder={null}
                         disabled={disabled}
                         tooltip={"چنانچه نیاز به توضیح مواردی هست که در فرم نیامده است"}/>
            {/*<Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>*/}
            {/*    <Checkbox>Remember me</Checkbox>*/}
            {/*</Form.Item>*/}
        </ProForm>
    )
}