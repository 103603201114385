import React from 'react';
import "./dashboard_page.css"
import * as Icons from '@ant-design/icons';

import ProLayout from '@ant-design/pro-layout/es';
import UserMenu from "../../components/user_menu/user_menu";
import {Route, Routes} from "react-router-dom";
import DashboardContainer from "./dashboard_container";
import UserProfile from "../user/user_profile";
import NotFoundPage from "../not_found_page";
import ObservationRequest from "../observation/observation_request";
import ObservationRequestList from "../observation/observation_request_list";
import ObservationRequestDetail from "../observation/observation_request_detail";
import {Avatar, Typography} from "antd";

const route = {
    path: '/',
    routes: [
        {
            path: '/',
            name: 'صفحه اصلی',
            icon: <Icons.DashboardOutlined/>,
        },
        {
            path: '/observation_request',
            name: 'درخواست رصد',
            icon: <Icons.FormOutlined/>,
        },
        {
            path: '/observation_request_list',
            name: 'لیست درخواست‌ها',
            icon: <Icons.UnorderedListOutlined/>,
        }
    ],
};

export default function DashboardPage() {

    return (
        <div className={"container"}>
            <ProLayout
                route={route}
                menuItemRender={(item, dom) => (
                    <a href={item.path}>
                        {dom}
                    </a>
                )}
                footerRender={
                    () => {
                        return (
                            <div className={"center"}>
                                <a href={"https://isao.isa.ir/"} target={"_blank"}>
                                    پورتال سازمان فضایی
                                </a>
                                <br/>
                                <a>
                                    ارتباط با ما
                                </a>
                            </div>
                        )
                    }
                }
                navTheme={"light"}
                headerTheme={"light"}
                menuHeaderRender={(logo, title) => (
                    <div>
                        <Avatar size={65} shape={"square"} src={logo}/>
                        {title}
                    </div>
                )}
                rightContentRender={UserMenu}
                layout={"top"}
                // layout={"mix"}
                logo={"/icons/icon-512.png"}
                // logo={<Avatar size={65} shape={"square"} src={"/icons/icon-512.png"}/>}
                fixSiderbar={true}
                fixedHeader={true}
                pwa={true}
                title={"سامانه کیهان"}
                // title={<Typography.Title level={2}>سامانه کیهان</Typography.Title>}
                subTitle={"رصد آنلاین از رصدخانه سازمان فضایی ایران"}
            >
                <Routes>
                    <Route path="/" element={<DashboardContainer/>}/>
                    <Route path="/profile" element={<UserProfile/>}/>
                    <Route path="/observation_request" element={<ObservationRequest/>}/>
                    <Route path="/observation_request/:id" element={<ObservationRequestDetail/>}/>
                    <Route path="/observation_request_list" element={<ObservationRequestList/>}/>
                    <Route path="*" element={<NotFoundPage/>}/>
                    {/*<Redirect path="/not_found" element={<NotFoundPage/>}/>*/}
                </Routes>
            </ProLayout>
        </div>
    )
}