import React, {useContext} from "react";
import {Menu, Modal, Space} from "antd";
import {DangerIcon, LogoutIcon} from "../../statics/icons";
import {UserContext} from "../contexts/user_context";
import {AVATAR, EMAIL, NAME} from "../../strings/field_names";
import UserMenuIcon from "./user_menu_icon";
import {userLogout} from "../../api/user_api";

export default function UserMenu() {
    const [user, updateUser] = useContext(UserContext);

    const userAvatarMenu = (
        <Menu>
            <Menu.Item key="detail">
                <span>
                    {user[NAME]}
                    <br/>
                    {user[EMAIL]}
                </span>
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item key="profile" onClick={() => {
                window.location.pathname = "/profile"
            }}>
                مشاهده پروفایل
            </Menu.Item>

            <Menu.Item icon={<LogoutIcon/>} key={"logout"} onClick={() => {
                Modal.confirm({
                    style: {direction: "rtl"},
                    title: "خروج از حساب کاربری",
                    icon: <DangerIcon/>,
                    content: "مطمئنید می‌خواهید خارج شوید؟",
                    okText: 'بله',
                    okType: 'danger',
                    cancelText: 'خیر',
                    maskClosable: true,
                    // okButtonProps: {loading: loading},
                    onOk() {
                        userLogout()
                    },
                });
            }}>
                {"خروج"}
            </Menu.Item>
        </Menu>
    );

    return (
        <Space style={{padding: 10}}>
            <UserMenuIcon image={user[AVATAR]} menu={userAvatarMenu} tooltip={user[EMAIL]}/>
        </Space>
    );
}