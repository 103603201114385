import React, {useRef, useState} from 'react';
import '../account_page.css';
import {message, Space, Divider} from 'antd';
import {DATA, FORM, METHOD, PASSWORD, RANDOM_KEY, TOKEN, USERNAME} from "../../../strings/field_names";
import Captcha from "../../../components/inputs/captcha";
import RandomNumberForm from "./random_number_form";
import {request} from "../../../api/api";
import {USER_LOGIN_URL} from "../../../strings/request_urls";
import ProForm, {ProFormText} from "@ant-design/pro-form";
import * as Icons from "@ant-design/icons";

export default function LoginForm() {
    const [randomKey, setRandomKey] = useState(null)
    const formRef = useRef();

    return (
        <div style={{direction: "rtl"}}>
            <RandomNumberForm randomKey={randomKey} setRandomKey={setRandomKey}
                              onFinish={(response) => {
                                  localStorage.setItem(TOKEN, response[TOKEN])
                                  window.location.pathname = "/"
                              }}/>
            <ProForm
                autoFocusFirstInput={true}
                isKeyPressSubmit={true}
                formRef={formRef}
                name={"login"}
                size={"large"}
                onFinish={async (values) => {
                    const response = await request(USER_LOGIN_URL, {
                        [METHOD]: "POST",
                        [DATA]: values,
                        [FORM]: formRef.current,
                    })
                    message.success("کد اعتبار سنجی ارسال شد").then()
                    setRandomKey(response[RANDOM_KEY])
                }}
                submitter={{
                    searchConfig: {
                        submitText: 'ورود',
                        resetText: 'ثبت نام',
                    },
                    resetButtonProps: {
                        onClick: () => {
                            window.location.pathname = "/register"
                        },
                        type: "danger"
                    },
                }}
            >
                <ProFormText
                    name={USERNAME}
                    fieldProps={{
                        prefix: <Icons.UserOutlined/>,
                        maxLength: 10
                    }}
                    placeholder={"کد ملی"}
                    rules={[{required: true}, {len: 10}]}
                />
                <ProFormText.Password
                    name={PASSWORD}
                    fieldProps={{
                        prefix: <Icons.LockOutlined/>,
                    }}
                    placeholder={"کلمه عبور"}
                    rules={[{required: true}, {min: 8}]}
                />
                <Captcha formRef={formRef}/>
            </ProForm>
            <Space direction="vertical" size={0}>
                <a href={"/forgot"}>
                    کلمه عبور را فراموش کرده‌اید؟
                </a>
                <Divider/>
                <a href={"https://isao.isa.ir/"} target={"_blank"}>
                    پورتال سازمان فضایی
                </a>
                <a href={"/about"}>
                    درباره سامانه
                </a>
                <a href={"/"}>
                    آموزش استفاده
                </a>
            </Space>

        </div>
    )
}