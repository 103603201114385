import React from 'react';
import './account_page.css';
import {Route, Routes} from "react-router-dom";
import LoginForm from "./forms/login_form";
import {Avatar} from 'antd';
import RegisterForm from "./forms/register_form";
import UserDetailForm from "./forms/user_detail_form";
import UserActiveError from "./user_active_error";
import ForgotPasswordForm from "./forms/forgot_password_form";
import ChangePasswordForm from "../user/change_password_form";
import AboutPage from "./about_page";
import BackgroundImage from '../../images/account_background.jpg';
import {AUTHENTICATED} from "../../strings/field_names";
import NotFoundPage from "../not_found_page";

export default function AccountPage({accountInfo}) {

    return (
        <div className={"account-body"}
             style={{backgroundImage: "url(" + BackgroundImage + ")"}}
        >
            <div className={"center"}>
                <a className={"account-title"} href={"/"}>
                    <Avatar size={128} src={"/icons/icon-128.png"} shape="square"/>
                    <div style={{fontSize: 30}}>
                        سامانه کیهان
                    </div>
                    <div style={{fontSize: 22}}>
                        رصد آنلاین از رصدخانه سازمان فضایی ایران
                    </div>
                </a>
            </div>
            <div className={"form"}>

                <Routes>
                    {accountInfo[AUTHENTICATED] ? null : <>
                        <Route path="/about" element={<AboutPage/>}/>
                        <Route path="/register" element={<RegisterForm/>}/>
                        <Route path="/forgot" element={<ForgotPasswordForm/>}/>
                        <Route path="*" element={<LoginForm/>}/>
                    </>}
                    {accountInfo["account_detail_page"] ?
                        <Route path="*" element={<UserDetailForm onFinish={() => window.location.pathname = "/"}/>}/>
                        : null}
                    {accountInfo["account_active_page"] ?
                        <Route path="*" element={<UserActiveError/>}/>
                        : null}
                    {accountInfo["account_password_page"] ?
                        <Route path="*" element={<ChangePasswordForm/>}/>
                        : null}
                    <Route path="*" element={<NotFoundPage/>}/>

                </Routes>

            </div>

        </div>
    )
}