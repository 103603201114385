import React from "react";
import {ProFormText} from "@ant-design/pro-form";


export default function ConfirmPassword({name, confirm, formRef}) {
    return (
        <div>
            <ProFormText.Password
                name={name}
                placeholder={"کلمه عبور جدید"}
                rules={[{required: true}, {type: "string", min: 8}]}
            />
            <ProFormText.Password
                name={confirm}
                placeholder={"تکرار رمز عبور جدید"}
                rules={[{required: true}, {type: "string", min: 8}, {
                    validator: (rule, value) => {
                        if (value && value !== formRef.current?.getFieldValue(name)) {
                            return Promise.reject('با رمز عبور تطابق ندارد');
                        }
                        return Promise.resolve();
                    }
                }]}
            />
        </div>
    );

}

