import React from "react";
import {ProFormRadio, ProFormText} from "@ant-design/pro-form";
import {APPARENT_VALUE, CONSTELLATION, MATERIAL_TYPE} from "../../../strings/field_names";
import Coordinate from "../../../components/inputs/coordinate";
import TelescopeFilter from "../../../components/inputs/telescope_filter";

export default function SpaceRequestForm({telescope, disabled}) {

    return (
        <div>
            <ProFormText disabled={disabled} name={APPARENT_VALUE} label={"قدر ظاهری"}
                         placeholder={null} rules={[{required: true}]}/>
            <ProFormRadio.Group
                disabled={disabled}
                name={MATERIAL_TYPE} label={"نوع جرم"}
                placeholder={null} rules={[{required: true}]}
                options={[
                    {
                        label: 'کهکشان',
                        value: 'G',
                    },
                    {
                        label: 'سحابی',
                        value: 'C',
                    },
                    {
                        label: 'خوشه کروی',
                        value: 'S',
                    },
                    {
                        label: 'خوشه باز',
                        value: 'O',
                    },
                ]}
            />
            <ProFormText disabled={disabled} name={CONSTELLATION} label={"صورت فلکی"}
                         placeholder={null} rules={[{required: true}]}/>

            <Coordinate disabled={disabled}/>
            <TelescopeFilter telescope={telescope} disabled={disabled}/>
        </div>
    )
}