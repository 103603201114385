import {Avatar, Dropdown, Tooltip} from "antd";
import React from "react";

export default function UserMenuIcon({image, tooltip, menu, onClick}) {
    return (
        <Dropdown overlay={menu} trigger={[onClick ? '' : 'click']}>
            <Tooltip placement="right" title={tooltip}>
                <Avatar size={"large"} src={image}/>
            </Tooltip>
        </Dropdown>
    );
}
