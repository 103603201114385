import ProTable from "@ant-design/pro-table";
import React, {useRef} from "react";
import {
    DATA,
    END_DT,
    ERROR,
    FORM,
    HANDLE,
    ID,
    INSERT_DT,
    METHOD,
    START_DT,
    SUITABLE_HEIGHT_DT,
    UNSUITABLE_HEIGHT_DT
} from "../../strings/field_names";
import {enToFa, TimeRender} from "../../statics/utils";
import {request} from "../../api/api";
import {HTTP_204_NO_CONTENT} from "../../strings/status_codes";
import {Button, message, Popconfirm} from "antd";
import {ModalForm, ProFormTimePicker} from "@ant-design/pro-form";
import * as Icons from '@ant-design/icons';
import JalaliDatetime from "../../components/inputs/jalali_datetime";
import {OBSERVATION_REQUEST_LIST_URL} from "../../strings/request_urls";

const AddTime = ({formRef, tableActionRef, timeUrl}) => (
    <ModalForm
        formRef={formRef}
        onFinish={async (values) => {
            const response = await request(timeUrl, {
                [METHOD]: "POST",
                [DATA]: values,
                [FORM]: formRef.current,
            })
            if (response) {
                message.success("زمان مورد نظر با موفقیت اضافه شد")
                tableActionRef.current?.reload()
                return true
            }
        }}
        trigger={
            <Button key="button" icon={<Icons.PlusOutlined/>} type="primary">
                افزودن تاریخ
            </Button>
        }>
        <JalaliDatetime formRef={formRef} name={START_DT} label={"زمان شروع"} rules={[{required: true}]}/>
        <JalaliDatetime formRef={formRef} name={END_DT} label={"زمان پایان"} rules={[{required: true}]}/>
        <ProFormTimePicker name={SUITABLE_HEIGHT_DT} label={"زمان رسیدن به ارتفاع مناسب"}/>
        <ProFormTimePicker name={UNSUITABLE_HEIGHT_DT} label={"زمان رسیدن به ارتفاع نامناسب"}/>
    </ModalForm>
)


export default function ObservationRequestTime({observationRequest, editable}) {
    const formRef = useRef()
    const tableActionRef = useRef()
    const timeUrl = OBSERVATION_REQUEST_LIST_URL + observationRequest + "/times/"

    const columns = [
        {
            dataIndex: START_DT,
            title: 'زمان شروع',
            tooltip: "زمان شروع رصد",
            width: 100,
            render: (time) => <TimeRender time={time}/>
        },
        {
            dataIndex: END_DT,
            title: 'زمان پایان',
            width: 100,
            render: (time) => <TimeRender time={time}/>
        },
        {
            dataIndex: SUITABLE_HEIGHT_DT,
            title: 'زمان رسیدن به ارتفاع مناسب',
            width: 100,
            render: (time) => enToFa(time)
        },
        {
            dataIndex: UNSUITABLE_HEIGHT_DT,
            title: "زمان رسیدن به ارتفاع نامناسب",
            width: 100,
            render: (time) => enToFa(time)
        },
        {
            dataIndex: INSERT_DT,
            title: "تاریخ درج",
            width: 100,
            render: (time) => <TimeRender time={time}/>
        },
        {
            title: 'حذف زمان',
            dataIndex: 'x',
            valueType: 'option',
            width: 60,
            render: (_, record) => {
                // let node = renderRemoveUser('退出');
                // if (record.role === 'admin') {
                //     node = renderRemoveUser('移除');
                // }
                if (!editable) {
                    return []
                }
                return [
                    <Popconfirm key="delete" title={"آیا برای حذف این مورد مطمئنید؟"} okText="بله" cancelText="خیر"
                                onConfirm={async () => {
                                    await request(timeUrl, {
                                        [METHOD]: "DELETE",
                                        [DATA]: {
                                            [ID]: record[ID],
                                        },
                                        [HANDLE]: {
                                            [ERROR]: () => {
                                                message.error("خطا در حذف زمان")
                                            },
                                            [HTTP_204_NO_CONTENT]: () => {
                                                message.success("زمان مورد نظر با موفقیت حذف شد")
                                                tableActionRef.current?.reload()
                                                return true
                                            }
                                        }
                                    })
                                }}>
                        <Icons.DeleteOutlined/>
                    </Popconfirm>,
                ];
            },
        },
    ];


    return (
        <div>
            <ProTable
                actionRef={tableActionRef}
                columns={columns}
                rowKey={ID}
                search={false}
                request={async () => {
                    const response = await request(timeUrl)
                    if (response) {
                        return {
                            ["data"]: response,
                            ["success"]: true,
                        }
                    }
                    return {
                        ["success"]: false
                    }
                }}
                headerTitle="لیست زمان‌های رصد"
                options={{
                    reload: true,
                    density: false,
                    setting: false,
                }}
                toolBarRender={() => [
                    editable ? <AddTime key={"add"} formRef={formRef} tableActionRef={tableActionRef}
                                        timeUrl={timeUrl}/> : null
                ]}
            />
        </div>
    )
}