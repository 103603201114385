import {Result, Image, Spin, Button} from 'antd';
import React from "react";

export default function NotFoundPage() {
    return (
        <Result
            status="404"
            title="صفحه مورد نظر یافت نشد"
            // subTitle="صفحه مورد نظر یافت نشد"
            extra={
                <a href={"/"}>
                    <Button type="primary">
                        بازگشت
                    </Button>
                </a>
            }
        />
    )
}