export const RESULT_TEXT = "result_text"
export const NEXT_URL = "next_url"
export const NEXT_PATHNAME = "NEXT_PATHNAME"
export const NEXT_SEARCH = "NEXT_SEARCH"
export const KEY = "key"
export const RANDOM_KEY = "random_key"
export const WIDTH = "width"
export const HEIGHT = "height"
export const VALUE = "value"
export const EMAIL = "email"
export const SMS = "sms"
export const IS_SUPERUSER = "is_superuser"
export const APPLICATION_COMPANY = "application_company"
export const BACKGROUND = "background"
export const LOGO = "logo"
export const COMPANY = "company"
export const COMPONENT = "component"
export const MATERIAL_NAME = "material_name"
export const MATERIAL_DETAIL_NAME = "material_detail_name"
export const TELESCOPE_FILTER_LIST = "telescope_filter_list"
export const TELESCOPE_FILTER = "telescope_filter"
export const CONFIRM = "confirm"

export const COORDINATE_LATITUDE_DEGREE = "coordinate_latitude_degree"
export const COORDINATE_LATITUDE_MINUTE = "coordinate_latitude_minute"
export const COORDINATE_LATITUDE_SECOND = "coordinate_latitude_second"
export const COORDINATE_LATITUDE_SIGN = "coordinate_latitude_sign"

export const COORDINATE_LONGITUDE_HOUR = "coordinate_longitude_hour"
export const COORDINATE_LONGITUDE_MINUTE = "coordinate_longitude_minute"
export const COORDINATE_LONGITUDE_SECOND = "coordinate_longitude_second"

export const NEED_FILTER = "need_filter"
export const NEED_COORDINATE = "need_coordinate"
export const PLACE_OF_USE = "place_of_use"
export const NEED_PLACE_OF_USE = "need_place_of_use"
export const SPECIAL_CONDITION = "special_condition"
export const NEED_SPECIAL_CONDITION = "need_special_condition"
export const COORDINATE_LATITUDE = "coordinate_latitude"
export const OBTAINED_COORDINATE_LATITUDE = "obtained_coordinate_latitude"
export const COORDINATE_LONGITUDE = "coordinate_longitude"
export const OBTAINED_COORDINATE_LONGITUDE = "obtained_coordinate_longitude"
export const REASON = "reason"
export const INSERT_DT = "insert_dt"
export const START_DT = "start_dt"
export const IGNORE_REASON = "ignore_reason"
export const END_DT = "end_dt"
export const SUITABLE_HEIGHT_DT = "suitable_height_dt"
export const UNSUITABLE_HEIGHT_DT = "unsuitable_height_dt"
export const USER_URL = "user_url"
export const DESCRIPTION = "description"
export const APPARENT_VALUE = "apparent_value"
export const RELATED_MATERIALS_NAME = "related_materials_name"
export const MATERIAL_TYPE = "material_type"
export const PURPOSE = "purpose"
export const PERIOD = "period"
export const CONSTELLATION = "constellation"
export const TLE = "tle"
export const IS_PROJECT = "is_project"
export const TITLE = "title"
export const SUB_TITLE = "sub_title"
export const REGISTER_DT = "register_dt"
export const LAST_CHECK_DT = "last_check_dt"
export const GRANTED_DT = "granted_dt"
export const JOIN_DT = "join_dt"
export const PERSONS_NUMBER = "persons_number"
export const APP_ID = "app_id"
export const APPLICATION_AGGREGATION = "application_aggregation"
export const LAST_VISIT_DT = "last_visit_dt"
export const LOGIN_DT = "login_dt"
export const CODE = "code"
export const STATE = "state"
export const PASSWORD = "password"
export const CAPTCHA = "captcha"
export const CAPTCHA_ANSWER = "captcha_answer"
export const CAPTCHA_KEY = "captcha_key"
export const IMAGE = "image"
export const PERSON = "person"
export const USER = "user"
export const PERSON_PHONE = "person_phone"
export const PERSON_DATA = "person_data"
export const SESSION = "session"
export const SERVER = "server"
export const DETAIL = "detail"
export const DETAIL_NAME = "detail_name"
export const FA_DETAIL = "fa_detail"
export const LEVEL = "level"
export const HASH_KEY = "hash_key"
export const SHOW = "show"
export const HEADER = "header"
export const ACTION_TYPE = "action_type"
export const TYPE = "type"
export const SEND = "send"
export const RECEIVE = "receive"
export const SEARCH = "search"
export const SENDER = "sender"
export const FILTER = "filter"
export const COLOR = "color"
export const FA_MODE = "fa_mode"
export const PAGE_SIZE = "pageSize"
export const CURRENT = "current"
export const POSITION = "position"
export const TOP_RIGHT_POSITION = "topRight"
export const TOP_POSITION = "top"
export const PERSON_MESSAGE = "person_message"
export const BOTTOM_RIGHT_POSITION = "bottomRight"
export const TOTAL = "total"
export const TEXT = "text"
export const SHORT = "short"
export const ID = "id"
export const RULE = "rule"
export const SELECTED = "selected"
export const NODE = "node"
export const PERSON_GROUP = "person_group"
export const RULE_TYPE = "rule_type"
export const RULE_TYPES_DATA = "rule_types_data"
export const RULES_DATA = "rules_data"
export const RANGE = "range"
export const ITEMS = "items"
export const TIME = "time"
export const ACTIVITY_LIST = "activity_list"
export const PERSONS = "persons"
export const CHANGE_DETAIL = "change_detail"
export const USER_DETAIL = "user_detail"
export const SET_USER_DETAIL = "set_user_detail"
export const FINAL = "final"
export const RESULTS = "results"
export const LOADING = "loading"
export const DELIVERY_VERSIONS = "delivery_versions"
export const STATIC_CHANGE = "static_change"
export const SIGNATURE_CHANGE = "signature_change"
export const APP_CHANGE = "app_change"
export const GIT_CHANGELOG = "git_changelog"
export const RESULT = "result"
export const TEST_CHANGELOG = "test_changelog"
export const APPSPEC_CHANGE = "appspec_change"
export const EMAIL_VERIFIED = "email_verified"
export const PHONE_VERIFIED = "phone_verified"
export const DORNA_VERIFIED = "dorna_verified"
export const PERMISSIONS = "permissions"
export const CONNECTION = "connection"
export const SECTION_PERMISSIONS = "section_permissions"
export const ERROR_LIST = "error_list"
export const FUNCTION = "function"
export const BOOLEAN = "boolean"
export const NUMBER = "number"
export const STRING = "string"
export const OBJECT = "object"
export const JSON_DATA = "json_data"
export const HANDLE = "handle"
export const NEED_DATA = "need_data"
export const CACHE_DATA = "cache_data"
export const CHECK_DATA = "check_data"
export const PERMISSION = "permission"
export const PERSON_SESSION = "person_session"
export const PERSON_SERVER = "person_server"
export const SSH_PUB_KEY = "ssh_pub_key"
export const NOT_PERSON = "not_person"
export const NEED_RULES = "need_rules"
export const GROUP = "group"
export const PROJECT = "project"
export const APPLICATION = "application"
export const PROJECT_PROJECT = PROJECT + "_" + PROJECT
export const APPLICATION_APPLICATION = APPLICATION + "_" + APPLICATION
export const APP_DB_NAME = "app_db"
export const NAME = "name"
export const STATUS = "status"
export const OWNER = "owner"
export const FILE = "file"
export const FILENAME = "filename"
export const FILES = "files"
export const DATA = "data"
export const METHOD = "method"
export const FORM = "form"
export const ORIGIN_FILE = "origin_file"
export const DESTINATION_FILE = "destination_file"
export const ORIGIN_APP = "origin_app"
export const DESTINATION_APP = "destination_app"
export const ADD_LINE = "add_line"
export const DELETE_LINE = "delete_line"
export const FA_NAME = "fa_name"
export const PERSON_RULE = "person_rule"
export const GROUP_RULE = "group_rule"
export const ACCESSOR = "accessor"
export const AVATAR = "avatar"
export const ADD = "add"
export const AVATAR_ID = "avatar_id"
export const SUBJECT = "subject"
export const BODY = "body"
export const COST = "cost"
export const SEND_DT = "send_dt"
export const CREATE_DT = "create_dt"
export const RELEASE_DT = "release_dt"
export const IS_DELETE = "is_delete"
export const MESSAGE_DATA = "message_data"
export const MESSAGE_TYPE = "message_type"
export const SEND_TYPE = "send_type"
export const SERVER_TYPE = "server_type"
export const DELIVERY_TYPE = "delivery_type"
export const VERSION = "version"
export const OBSERVATION_SUBJECT = "observation_subject"
export const OBSERVATION_STATE = "observation_state"
export const OBSERVATION_PURPOSE = "observation_purpose"
export const EDUCATION_GRADE = "education_grade"
export const EDUCATION_FIELD = "education_field"
export const PROVINCE = "province"
export const CITY = "city"
export const PURPOSE_DETAIL = "purpose_detail"
export const NEED_DETAIL = "need_detail"
export const OBSERVATION_REQUEST = "observation_request"
export const OBSERVATION_REQUEST_TIME = "observation_request_time"
export const TELESCOPE = "telescope"
export const FULL_DETAIL = "full_detail"
export const LABEL = "label"
export const MESSAGE = "message"
export const REQUIRED = "required"
export const MESSAGE_STATUS = "message_status"
export const MESSAGE_RESULT = "message_result"
export const MESSAGE_FILTERS = "message_filters"
export const TAG = "tag"
export const MESSAGES_LIST = "messages_list"
export const COUNT = "count"
export const OUTPUT_DATA = "output_data"
export const RESULT_DATA = "result_data"
export const URL = "url"
export const DATA_LIST = "data_list"
export const TOKEN = "token"
export const ACCOUNT = "account"
export const ERROR = "error"
export const SUCCESS = "success"
export const FAIL = "fail"
export const FINISH = "finish"
export const AUTHENTICATED = "authenticated"
export const TOKEN_IS_VALID = "token_is_valid"
export const CONFIRM_PASSWORD = "confirm_password"
export const NEW_PASSWORD = "new_password"
export const FIRST_NAME = "first_name"
export const LAST_NAME = "last_name"
export const EN_FIRST_NAME = "en_first_name"
export const EN_LAST_NAME = "en_last_name"
export const DEVICE_NAME = "device_name"
export const GENDER = "gender"
export const APPLICATION_CATEGORY = "application_category"
export const APPLICATION_GIT = "application_git"
export const GENDER_LIST = "gender_list"
export const UPDATE_CONNECTION = "UPDATE_CONNECTION"
export const UPDATE_PERSON = "UPDATE_PERSON"
export const DELETE_PERSON = "DELETE_PERSON"
export const DELETE_KEY = "DELETE_KEY"
export const UPDATE_QUERY = "UPDATE_QUERY"
export const SET_KEY = "SET_KEY"
export const SET_MENU_KEY = "SET_MENU_KEY"
export const CHANGE_LOCATION = "CHANGE_LOCATION"
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION"
export const SET_BACKGROUND_COLOR = "SET_BACKGROUND_COLOR"
export const BACKGROUND_COLOR = "BACKGROUND_COLOR"
export const SET_BACKGROUND_ANIMATION = "SET_BACKGROUND_ANIMATION"
export const BACKGROUND_ANIMATION = "BACKGROUND_ANIMATION"
export const IS_ARCHIVE = "is_archive"
export const ARCHIVE = "archive"
export const UPDATE = "update"
export const DELETE = "delete"
export const DOWNLOAD = "download"
export const EDIT = "edit"
export const PHONE = "phone"
export const DORNA = "dorna"
export const ROOM = "room"
export const USERNAME = "username"
export const IP = "ip"
export const PORT = "port"
export const RESEND = "resend"
export const ACTION = "action"
export const CHANGE = "change"
export const READ = "read"
export const PREFIX = "prefix"
export const IS_VERIFIED = "is_verified"
export const IS_ACTIVE = "is_active"
export const USE_MIDDLE = "use_middle"
export const LAST_NUMBER = "last_number"
export const ORIGIN = "origin"
export const DESTINATION = "destination"
export const ADDRESS = "address"
