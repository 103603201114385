import React from "react";
import {Form, Input, Select} from "antd";
import {
    COORDINATE_LATITUDE_DEGREE,
    COORDINATE_LATITUDE_MINUTE,
    COORDINATE_LATITUDE_SECOND,
    COORDINATE_LATITUDE_SIGN,
    COORDINATE_LONGITUDE_HOUR,
    COORDINATE_LONGITUDE_MINUTE,
    COORDINATE_LONGITUDE_SECOND
} from "../../strings/field_names";

export default function Coordinate({disabled}) {
    return (
        <div>
            <Form.Item label={"مختصات بعد"}>
                <Input.Group compact>
                    <Form.Item
                        name={COORDINATE_LATITUDE_DEGREE}
                        noStyle
                    >
                        <Input disabled={disabled} placeholder={"درجه"} style={{width: '22%'}}/>
                    </Form.Item>
                    <Form.Item
                        name={COORDINATE_LATITUDE_MINUTE}
                        noStyle
                    >
                        <Input disabled={disabled} placeholder={"دقیقه"} style={{width: '24%'}}/>
                    </Form.Item>
                    <Form.Item
                        name={COORDINATE_LATITUDE_SECOND}
                        noStyle
                    >
                        <Input disabled={disabled} placeholder={"ثانیه"} style={{width: '22%'}}/>
                    </Form.Item>
                    <Form.Item
                        name={COORDINATE_LATITUDE_SIGN}
                        noStyle
                    >
                        <Select disabled={disabled} style={{width: '32%'}}>
                            <Select.Option value={1}>
                                شمالی
                            </Select.Option>
                            <Select.Option value={-1}>
                                جنوبی
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Input.Group>
            </Form.Item>

            <Form.Item label={"مختصات میل"}>
                <Input.Group compact>
                    <Form.Item
                        name={COORDINATE_LONGITUDE_HOUR}
                        noStyle
                    >
                        <Input disabled={disabled} placeholder={"ساعت"} style={{width: '22%'}}/>
                    </Form.Item>
                    <Form.Item
                        name={COORDINATE_LONGITUDE_MINUTE}
                        noStyle
                    >
                        <Input disabled={disabled} placeholder={"دقیقه"} style={{width: '24%'}}/>
                    </Form.Item>
                    <Form.Item
                        name={COORDINATE_LONGITUDE_SECOND}
                        noStyle
                    >
                        <Input disabled={disabled} placeholder={"ثانیه"} style={{width: '22%'}}/>
                    </Form.Item>
                </Input.Group>
            </Form.Item>


        </div>
    )
}