import {Button, Form, Statistic} from 'antd';
import React, {useState} from "react";

const waitTime = (time = 100) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};

export default function ResendCode({onResend, seconds = 60}) {
    const [disable, setDisable] = useState(true);
    const [loading, setLoading] = useState(false);

    return (
        <Form.Item>
            {disable ?
                <Statistic.Countdown
                    valueStyle={{fontSize: 15}}
                    prefix={"ارسال مجدد کد در"}
                    format={"ss"}
                    suffix={"ثانیه"}
                    value={Date.now() + 1000 * seconds}
                    onFinish={() => {
                        setDisable(false)
                    }}/> :
                <Button type="default" style={{width: '100%'}}
                        disabled={disable}
                        loading={loading}
                        onClick={async () => {
                            setLoading(true)
                            await waitTime(3000)
                            await onResend()
                            setLoading(false)
                            setDisable(true)
                        }}>
                    ارسال مجدد کد فعال سازی
                </Button>
            }
        </Form.Item>
    );

}