import {Form, Image, Spin} from 'antd';
import React, {useCallback, useEffect, useState} from "react";
import {CAPTCHA_ANSWER, CAPTCHA_KEY, IMAGE, KEY} from "../../strings/field_names";

import {ProFormText} from '@ant-design/pro-form';
import * as Icons from '@ant-design/icons';
import {request} from "../../api/api";
import {USER_CAPTCHA_URL} from "../../strings/request_urls";

export default function Captcha({formRef}) {
    const [captcha, setCaptcha] = useState({})
    const [loading, setLoading] = useState(false)

    const updateCaptcha = useCallback(async () => {
        setLoading(true)
        const response = await request(USER_CAPTCHA_URL, {})
        setCaptcha(response)
        formRef.current?.setFieldsValue({
            [CAPTCHA_KEY]: response[KEY],
            [CAPTCHA_ANSWER]: null
        })
        setLoading(false)
    }, [])

    useEffect(() => {
        updateCaptcha().then()
    }, [])

    return (
        <div>
            <Form.Item style={{textAlign: "center"}} name={CAPTCHA_KEY} colon={false}>
                {loading? <Spin size="large"/>: <span>
                    {captcha[IMAGE] ? <Image
                        width={220}
                        src={captcha[IMAGE]}
                    /> : null}
                    {" "}
                    <Icons.ReloadOutlined onClick={() => {
                        updateCaptcha().then()
                    }}/>
                </span>}
            </Form.Item>
            <ProFormText
                name={CAPTCHA_ANSWER}
                fieldProps={{
                    prefix: <Icons.QrcodeOutlined/>,
                    maxLength: 6
                }}
                placeholder={"عبارت امنیتی"}
                rules={[{required: true}, {len: 6}]}
            />
        </div>
    )
}